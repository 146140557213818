@layer components {
  .button-primary {
    background-image: linear-gradient(
      272.59deg,
      #0957c3 0%,
      #0957c3 42.19%,
      #5085cf 74.48%,
      #81acdb 99.44%
    );
    &:hover {
      background-image: linear-gradient(
        272.59deg,
        #0957c3 0%,
        #0957c3 16.15%,
        #81acdb 73.44%
      );
    }
    &:active {
      background-image: linear-gradient(272.59deg, #5085cf 0%, #81acdb 100%);
    }
    @apply flex items-center justify-center outline-none cursor-pointer font-sans text-p text-beige-main rounded-full px-8 border-0 no-underline
           disabled:bg-none disabled:bg-beige-main disabled:text-grey-main disabled:text-opacity-40 disabled:border disabled:border-grey-main disabled:border-opacity-20 disabled:pointer-events-none;
    padding-top: 18px;
    padding-bottom: 18px;
    &:disabled {
      padding: 17px 31px;
    }
  }
  .button-combo-on-white {
    @apply flex items-center justify-center outline-none cursor-pointer bg-white-main border border-blue-mid font-sans text-p text-blue-main rounded-full
           hover:bg-blue-light hover:border-blue-light
           focus:bg-blue-mid focus:border-blue-mid
           disabled:text-grey-main disabled:text-opacity-40 disabled:border-grey-main disabled:border-opacity-10 disabled:pointer-events-none;
    padding: 17px 31px;
  }
  .button-secondary {
    @apply flex items-center justify-center outline-none cursor-pointer font-sans text-p text-blue-main rounded-full px-8 border no-underline border-blue-mid
           hover:border-blue-light hover:bg-blue-light
           focus:bg-blue-mid focus:border-blue-mid
           disabled:text-grey-main disabled:text-opacity-40 disabled:border-grey-main disabled:border-opacity-10 disabled:pointer-events-none;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
