@import "@csstools/normalize.css";
@import "sanitize.css";

h4,
h1,
h2,
h3,
h5,
h6,
p {
  margin-block-end: 0px;
  margin-block-start: 0px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
}

[hidden] {
  display: none !important;
}
