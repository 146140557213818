@layer components {
  .stripe-wrapper {
    @apply h-14 relative w-full max-w-452 mt-2 border border-grey-super-light rounded-lg;
    padding: 27px 9px 9px 9px;
    &--focus {
      @apply border-2 border-yellow-main;
      padding: 26px 8px 8px 8px;
      span {
        top: 7px;
        left: 9px;
      }
    }
  }
}
