.cart-messages {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 16px;
  top: 8px;
  width: calc(100% - 32px);
  z-index: 1001;
}
.cart-message {
  align-items: center;
  background: #4a4847;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(160, 117, 23, 0.15);
  color: #4a4847;
  display: flex;
  flex-direction: row;
  min-height: 56px;
  padding: 8px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  &__button {
    background: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    height: 24px;
    margin-left: auto;
    padding: 0px;
    width: 24px;
    &:hover,
    &:focus {
      background: #dee1e7;
    }
    &:active {
      background: #afc2df;
    }
  }
  &__button-icon {
    background: url("../images/cart/message-close.svg") no-repeat;
    background-position: right 7px top 7px;
    display: flex;
    height: 24px;
    width: 24px;
  }
  &__price-adjust {
    display: flex;
    flex-direction: column;
  }
  &__text {
    align-items: center;
    color: #faf8f6;
    display: flex;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
  &__action-link {
    background: none;
    border: none;
    color: #ffb81c;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding: 0px;
  }
  .price-adjust {
    &__value {
      &--old {
        background-image: linear-gradient(180deg, #aca8a5 0, #aca8a5);
        background-position: 0px 10px;
        background-repeat: repeat-x;
        background-size: 1px 1px;
        color: #aca8a5;
        display: flex;
        font-family: var(--font-main);
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
      }
      &--new {
        color: #faf8f6;
        display: flex;
        font-family: var(--font-main);
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        margin-left: 8px;
      }
    }
  }
}
