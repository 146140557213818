.paragraph {
  margin-top: 16px;
  @mixin body-regular;
  padding: 0 16px;
  a {
    color: #0957C3;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    &:hover, &:active, &:focus {
      color: #6794D3;
    }
  }
}

@mixin tablet {
  .paragraph {
    width: 100%;
    max-width: 452px;
    align-self: center;
    padding: 0px;
  }
}

@mixin desktop {
  .paragraph {
    max-width: 660px;
  }
}