@import "tailwind/index.css";
@import "reset.css";
@import "fonts.css";
@import "variable.css";
@import "mixins.css";
@import "a-text-style.css";
@import "components/index.css";
@import "header/index.css";
@import "catalog/index.css";
@import "articles/a-article.css";
@import "background-block.css";
@import "articles/show.css";
@import "shopping_cart/voucher.css";
@import "cart/index.css";
@import "checkout/index.css";
@import "product/index.css";
@import "shared/delivery-select.css";
@import "editorjs/index.css";
@import "shared/select-colors.css";
@import "products/specifications.css";
@import "shared/sad-popup.css";
@import "country_suggestion/country_suggestion.css";

html {
  position: relative;
  width: 100%;
}
body {
  background-color: var(--color-beige);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
}

.page-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  &--row {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.share-button {
  display: none !important;
}
