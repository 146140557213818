.cart-show-sad {
  border-top: 1px solid #e2ddd9;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top: 18px;
  width: 100%;
  &__container {
    @mixin body-regular-ui;
    color: #4a4847;
    cursor: pointer;
    display: block;
    padding-left: 28px;
    padding-right: 36px;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    &:hover input ~ .checkmark {
    }
    .checkmark {
      background: none;
      border: 1px solid #afc2df;
      border-radius: 4px;
      box-sizing: border-box;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
      &:checked ~ .checkmark {
        background: #ffb81c url("../images/cart/checkbox-check.svg") no-repeat
          right 50% top 50%;
        border: 1px solid #ffb81c;
      }
    }
  }
}

@mixin tablet {
  .cart-show-sad {
    position: relative;

    &__container {
      padding-right: 28px;
    }
  }
}
