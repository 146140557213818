.article {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  &__avatar-wrapper {
    display: flex;
    flex-direction: row;
    grid-column: 1 / span 2;
    margin-top: 40px;
  }
  &__avatar-image {
    border-radius: 48px;
    flex-shrink: 0;
    height: 96px;
    width: 96px;
  }
  &__avatar-content {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }
  &__avatar-content-name {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    a {
      color: #0957c3;
      font-family: var(--font-main);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;
      text-decoration: none;
      &:hover,
      &:active {
        color: #7fade3;
      }
    }
  }
  &__avatar-content-bio {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
  }
  &__tags {
    border-bottom: 1px solid #dddad6;
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    padding: 24px 0px;
  }
  &__tags-title {
    color: #9a9c9a;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
  }
  &__tags-list {
    color: #565e5f;
    display: inline-flex;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    margin-top: 12px;
  }
  &__tag {
    cursor: pointer;
    text-decoration: none;
    &:not(:first-child) {
      margin-left: 4px;
    }
    &:hover {
      color: #7fade3;
    }
  }
  &__social-buttons {
    border-bottom: 1px solid #dddad6;
    display: flex;
    flex-direction: row;
    grid-column: 1 / span 2;
    margin-top: 40px;
    padding-bottom: 40px;
  }
  &__social-button {
    background: #e8e6e3;
    border-radius: 8px;
    color: #0957c3;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    padding: 16px 16px 16px 8px;
    text-decoration: none;
    &:not(:first-child) {
      margin-left: 8px;
    }
    svg {
      margin-right: 4px;
    }
    &:hover {
      background: #c4d5e8;
      color: #0957c3;
    }
    &:active {
      background: #a9bedb;
      color: #0957c3;
    }
  }
  &__wrapper {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  &__simple-image-wrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    margin: 24px 0px 0px;
    padding: 0px;
    &--link {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__simple-image {
    border-radius: 24px;
    object-fit: cover;
    width: 100%;
  }
  &__simple-image-caption {
    color: #9a9c9a;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
    line-height: 24px;
    margin-top: 8px;
  }
  &__main-image {
    border-radius: 24px;
    grid-column: 1 / span 2;
    max-width: 1344px;
    object-fit: cover;
    width: 100%;
  }
  &__published-at {
    color: #b2b3b2;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    grid-column: 1 / span 2;
    line-height: 24px;
    margin-top: 24px;
  }
  &__header-wrapper {
    display: inline-flex;
    grid-column: 1 / span 2;
  }
  &__header-text {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 36px;
    font-style: normal;
    font-weight: normal;
    line-height: 40px;
    &--theme {
      color: #ff7500;
      font-style: italic;
      margin-right: 10px;
    }
  }
  &__lead-text {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    grid-column: 1 / span 2;
    line-height: 32px;
    margin-top: 16px;
  }
  &__h2-text {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    grid-column: 1 / span 2;
    line-height: 32px;
    margin-top: 40px;
    a {
      color: #0957c3;
      text-decoration: none;
      &:hover,
      &:active {
        color: #7fade3;
      }
    }
  }
  &__paragraph {
    color: #565e5f;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    grid-column: 1 / span 2;
    line-height: 24px;
    margin-top: 24px;
    a {
      color: #0957c3;
      text-decoration: none;
      &:hover,
      &:active {
        color: #7fade3;
      }
    }
  }
  &__quote-wrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    margin-top: 24px;
    padding-top: 32px;
    position: relative;
    a {
      color: #0957c3;
      text-decoration: none;
      &:hover,
      &:active {
        color: #7fade3;
      }
    }
    svg {
      left: 0px;
      position: absolute;
      top: 0px;
      z-index: 1;
    }
  }
  &__quote-text {
    color: #ff7500;
    font-family: var(--font-main);
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    z-index: 2;
  }
  &__quote-author {
    color: #ff7500;
    font-family: var(--font-main);
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    margin-top: 8px;
    text-align: right;
    z-index: 2;
  }
  &__header-wrapper + &__quote-wrapper,
  &__header-wrapper + &__paragraph,
  &__header-wrapper + &__avatar-wrapper,
  &__header-wrapper + &__simple-image-wrapper {
    margin-top: 16px;
  }
  &__lead-text + &__paragraph,
  &__lead-text + &__quote-wrapper,
  &__lead-text + &__simple-image-wrapper {
    margin-top: 40px;
  }
  &__quote-wrapper + &__paragraph {
    margin-top: 48px;
  }
}
@mixin tablet {
  .article {
    padding: 0px 48px;
    &__wrapper {
      display: grid;
      max-width: 768px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto [last-line];
    }
    &__main-image {
      height: 560px;
    }
    &__published-at {
      margin-top: 48px;
      grid-column: 2 / span 10;
    }
    &__tags,
    &__social-buttons,
    &__header-wrapper,
    &__lead-text,
    &__h2-text,
    &__paragraph,
    &__quote-wrapper,
    &__simple-image,
    &__avatar-wrapper {
      grid-column: 2 / span 10;
    }
  }
}
@mixin desktop {
  .article {
    &__wrapper {
      max-width: 928px;
    }
    &__tags-title,
    &__tags-list,
    &__avatar-content-bio {
      font-size: 16px;
      line-height: 24px;
    }
    &__avatar-content-name {
      margin-top: 8px;
    }
    &__avatar-wrapper {
      flex-direction: column;
      grid-column: 10 / span 3;
    }
    &__avatar-content {
      margin-left: 0px;
    }
    &__social-buttons {
      margin-top: 48px;
    }
    &__header-wrapper,
    &__published-at {
      grid-column: 1 / span 12;
    }
    &__header-text {
      font-size: 48px;
      line-height: 56px;
    }
    &__lead-text {
      margin-top: 24px;
      font-size: 32px;
      line-height: 44px;
      grid-column: 1 / span 12;
    }
    &__h2-text {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      margin-top: 48px;
    }
    &__paragraph {
      font-weight: 300;
      font-size: 22px;
      line-height: 32px;
    }
    &__tags {
      flex-direction: row;
    }
    &__tags-title {
      width: 90px;
    }
    &__tags-list {
      margin-left: 24px;
      margin-top: 0px;
    }
    &__tags,
    &__social-buttons,
    &__h2-text,
    &__paragraph,
    &__quote-wrapper,
    &__simple-image-wrapper {
      grid-column: 1 / span 8;
    }
    &__quote-text {
      font-weight: 300;
      font-size: 32px;
      line-height: 44px;
    }
    &__quote-author {
      font-size: 16px;
      line-height: 24px;
    }
    &__header-wrapper + &__avatar-wrapper,
    &__lead-text + &__avatar-wrapper {
      grid-row-start: span 9000;
    }
    &__header-wrapper + &__paragraph,
    &__header-wrapper + &__quote-wrapper,
    &__header-wrapper + &__avatar-wrapper,
    &__header-wrapper + &__simple-image-wrapper {
      margin-top: 24px;
    }
    &__header-wrapper + &__avatar-wrapper + &__paragraph,
    &__header-wrapper + &__avatar-wrapper + &__quote-wrapper,
    &__header-wrapper + &__avatar-wrapper + &__simple-image-wrapper {
      margin-top: 16px;
    }
    &__avatar-wrapper + &__paragraph,
    &__lead-text + &__paragraph &__lead-text + &__quote-wrapper,
    &__lead-text + &__avatar-wrapper,
    &__lead-text + &__simple-image-wrapper {
      margin-top: 48px;
    }
  }
}
@mixin desktop-big {
  .article {
  }
}
