.a-main-nav {
  display: flex;
  height: inherit;
  padding-left: 16px;
  width: 100%;
  &__list {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: inherit;
    justify-content: space-between;
    margin: 0px;
    width: 100%;
  }
  &__link {
    color: #4a4847;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    text-decoration: none;
    &:hover {
      background: linear-gradient(to right, #5085cf, #5085cf);
      background-position: 0px 28px;
      background-repeat: repeat-x;
      background-size: 1px 1px;
      color: #5085cf;
      cursor: pointer;
    }
    &--icon {
      height: 40px;
      position: relative;
      width: 40px;
      .link-bg {
        background: #7fade3;
        border-radius: 16px;
        display: flex;
        height: 32px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 4px;
        width: 32px;
      }
      &:after,
      &:before {
        content: "";
        height: 40px;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 40px;
        z-index: 1;
      }
      &:after {
        opacity: 0;
      }
      &:before {
        opacity: 1;
      }
      &:hover {
        background: none;
        .link-bg {
          opacity: 1;
        }
        &:after {
          opacity: 1;
        }
        &:before {
          opacity: 0;
        }
      }
    }
  }
  &__item {
    align-items: center;
    display: flex;
    &:before {
      content: none;
    }
    &--checkout {
      order: 2;
    }
    &--shop {
      margin-right: 4px;
      order: 4;
      .link-text-shop {
        display: none;
      }
      .a-main-nav__link {
        &:after {
          background: transparent url("../images/header/m-shop-hover.svg")
            no-repeat right 50% top 50%;
        }
        &:before {
          background: transparent url("../images/header/m-shop-default.svg")
            no-repeat right 50% top 50%;
        }
      }
    }
    &--search {
      display: flex;
      order: 3;
      .a-main-nav__link {
        padding: 10px;
        &:after {
          background: transparent url("../images/header/m-search-hover.svg")
            no-repeat right 50% top 50%;
        }
        &:before {
          background: transparent url("../images/header/m-search.svg") no-repeat
            right 50% top 50%;
        }
      }
    }
    &--country {
      &:hover {
        .country-select__value {
          color: #5085cf;
        }
      }
    }
    &--country,
    &--user,
    &--support {
      display: none;
    }
    &--checkout {
      @mixin title-h2;
    }
    &--logo {
      order: 1;
      .a-main-nav__link {
        background: transparent url("../images/header/logo-yellow.svg") no-repeat
          left 0px top 50%;
        background-size: 75px 20px;
        display: flex;
        height: 36px;
        padding: 8px;
        width: 85px;
      }
    }

    &--cart {
      display: flex;
      justify-content: center;
      margin-left: auto;
      order: 2;
      .a-main-nav__link {
        color: #4a4847;
        padding: 4px;
        &:before {
          background: transparent url("../images/header/m-cart-default.svg")
            no-repeat right 50% top 50%;
          content: "";
          height: 24px;
          left: 8px;
          top: 7px;
          width: 24px;
          z-index: 2;
        }
        &:after {
          background: transparent url("../images/header/m-cart-hover.svg")
            no-repeat right 50% top 50%;
          z-index: 2;
        }
        .link-bg {
          z-index: 1;
        }
        .cart-text {
          position: relative;
          z-index: 2;
        }
        &:hover {
          color: #f5f0eb;
        }
      }
    }
    &--cart-more-10 {
      .a-main-nav__link {
        font-size: 13px;
      }
    }
    &--cart-fill {
      .a-main-nav__link {
        .cart-text {
          display: flex;
          font-size: 12px;
          height: 32px;
          justify-content: center;
          line-height: 20px;
          padding-top: 7px;
          width: 32px;
        }
        .link-bg {
          background: #ffb81c;
          opacity: 1;
        }
      }
    }
  }
}

@mixin tablet {
  .a-main-nav {
    display: flex;
    padding: 0 48px;
    align-items: center;
    background: inherit;
    &__list {
      position: relative;
      background: inherit;
      align-items: flex-start;
      padding-top: 12px;
    }
    &__link {
      &--icon {
        .link-bg {
          border-radius: 20px;
          height: 40px;
          left: 0px;
          top: 0px;
          width: 40px;
        }
      }
    }
    &__item {
      &--shop {
        order: 1;
        width: auto;
        height: auto;
        position: relative;
        .a-main-nav__link {
          height: auto;
          width: auto;
          &:after,
          &:before {
            content: none;
          }
          &--icon {
            .link-bg {
              display: none;
            }
          }
          &:hover {
            padding: 8px;
            background: linear-gradient(to right, #5085cf, #5085cf);
            background-position: left 8px top 28px;
            background-size: calc(100% - 16px) 1px;
            background-repeat: no-repeat;
          }
        }
        .link-text-shop {
          display: flex;
        }
      }
      &--country {
        order: 3;
      }
      &--search {
        order: 4;
        margin-left: auto;
      }
      &--user {
        order: 5;
      }
      &--cart {
        order: 6;
      }
      &--shop {
        margin-left: -8px;
        margin-right: 0px;
      }
      &--shop,
      &--support {
        height: 40px;
        display: flex;
        align-items: flex-end;
        .a-main-nav__link {
          padding: 8px;
          background-position: left 8px top 28px;
          background-size: calc(100% - 16px) 1px;
          background-repeat: no-repeat;
        }
        flex-grow: 0;
      }
      &--country {
        display: flex;
        margin-right: auto;
        height: 40px;
        display: flex;
        align-items: center;
        align-items: flex-end;
        margin-left: 0px;
      }
      &--support {
        order: 2;
        margin-left: 0px;
      }
      &--logo {
        position: absolute;
        right: 50%;
        margin-right: -65px;
        align-self: flex-start;
        top: 4px;
        .a-main-nav__link {
          background: transparent url("../images/header/logo-yellow.svg") no-repeat
            right 50% top 50%;
          background-size: 130px 35px;
          display: flex;
          height: 40px;
          padding: 0px 8px;
          width: 130px;
        }
      }
      &--cart {
        margin: 0 4px;
        .a-main-nav__link {
          padding: 0px;
          &:after {
            background: transparent url("../images/header/d-cart-hover.svg")
              no-repeat right 50% top 50%;
            height: 40px;
            width: 40px;
            top: 0px;
            left: 0px;
          }
          &:before {
            background: transparent url("../images/header/d-cart-default.svg")
              no-repeat right 50% top 50%;
            height: 40px;
            width: 40px;
            top: 0px;
            left: 0px;
          }
        }
      }
      &--cart-fill {
        .a-main-nav__link {
          .cart-text {
            width: 40px;
            height: 40px;
            padding-top: 10px;
            font-size: 16px;
            line-height: 24px;
          }
          &:before {
            width: 40px;
            height: 40px;
            left: 0px;
            top: 0px;
            border-radius: 20px;
          }
        }
      }
      &--search {
        margin: 0 4px;
        display: flex;
        .a-main-nav__link {
          display: flex;
          height: 40px;
          position: relative;
          width: 40px;
          padding: 0px;
          &:before {
            background: transparent url("../images/header/d-search-default.svg")
              no-repeat right 50% top 50%;
          }
          &:after {
            background: transparent url("../images/header/d-search-hover.svg")
              no-repeat right 50% top 50%;
          }
        }
      }
      &--user {
        display: flex;
        margin: 0 4px;
        .a-main-nav__link {
          &:before {
            background: transparent url("../images/header/d-user-default.svg")
              no-repeat right 50% top 50%;
          }
          &:after {
            background: transparent url("../images/header/d-user-hover.svg");
          }
        }
      }
    }
  }
}
