.a-delivery {
  display: flex;
  flex-direction: column;
  &__country {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__text {
    @mixin body-semibold-ui;
    color: #4a4847;
    margin-left: 8px;
  }
  #change-country-form {
    margin-left: 0px;
  }
  &__country-select-value {
    color: #0957c3;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    margin-left: 4px;
    padding-bottom: 8px;
    padding-top: 8px;
    position: absolute;
    white-space: nowrap;
    z-index: -1;
    &:after {
      background: transparent url("../images/shared/arrow_default.svg")
        no-repeat right 50% top 50%;
      background-size: 16px 20px;
      content: "";
      height: 20px;
      position: absolute;
      right: -20px;
      top: 8px;
      width: 16px;
    }
  }
  &__country-select-wrapper {
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:hover {
      .a-delivery__country-select-value {
        color: #6794d3;
        cursor: pointer;
        &:after {
          background: transparent url("../images/shared/arrow_hover.svg")
            no-repeat right 50% top 50%;
          background-size: 16px 20px;
        }
      }
    }
  }
  &__country-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    opacity: 1;
    outline: none;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-top: 8px;
    position: relative;
    text-indent: 1px;
    text-overflow: "";
    width: 100%;
    &::-ms-expand {
      display: none;
    }
    &:focus-visible {
    }
    option {
      color: var(--color-gray);
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
  }
  &__terms {
    display: inline;
    padding-left: 32px;
    width: 100%;
  }
}
