@layer components {
  .editorjs-about-category {
    p {
      @apply body-text text-grey-main flex flex-col;
      b {
        @apply pt-4;
      }
      br {
        @apply mt-2;
        display: block;
        content: "";
      }
      b + br {
        @apply mt-0;
        display: block;
        content: "";
      }
      br + b {
        @apply pt-2;
      }
      a + br {
        @apply mt-0;
        display: block;
        content: "";
      }
      a {
        @apply mt-2 body-text text-blue-main cursor-pointer no-underline hover:text-blue-light-main;
      }
    }
  }
}
