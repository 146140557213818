.a-article {
  align-items: center;
  color: #4a4847;
  display: flex;
  flex-direction: column;
  width: 100%;
  .blog-html-block {
    width: 100%;
  }
  &__header {
    background: #ffb81c;
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    padding-top: 80px;
    width: 100%;
    &--without-image {
      padding-bottom: 48px;
    }
  }
  &__header-inner {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
  }
  &__header-text {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    .dot {
      margin: 0 8px;
    }
  }
  &__header-title {
    color: #4a4847;
    margin-top: 8px;
    @mixin title-h1-big-light;
    padding-left: 16px;
    padding-right: 16px;
  }
  &__main-image {
    margin-top: 40px;
    max-width: 1440px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    a {
      color: #0957c3;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
         color: #6794d3;
       }
      }
  }
  &__lead-text {
    @mixin title-h2-light;
    margin-top: 40px;
  }
  &__simple-image-wrapper {
    margin-top: 28px;
    padding: 16px 0px;
    .a-article__simple-image {
      object-fit: cover;
      width: 100%;
    }
    .a-article__simple-image-caption {
      @mixin caption-semibold;
      align-items: center;
      color: #7a7775;
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      padding: 0 16px;
    }
  }
  &__quote-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 32px 16px 16px 16px;
    position: relative;
    .quote-start,
    .quote-end {
      background-size: 88px 96px;
      display: flex;
      height: 96px;
      position: absolute;
      user-select: none;
      width: 88px;
      z-index: -1;
    }
    .quote-start {
      background: transparent url("../images/blog/quote-start.svg") no-repeat
        left 0px top 0px;
      left: 0px;
      top: 0px;
    }
    .quote-end {
      background: transparent url("../images/blog/quote-end.svg") no-repeat left
        0px top 0px;
      bottom: 0px;
      right: 0px;
    }
  }
  &__quote-text {
    display: flex;
    flex-direction: column;
    &--first {
      @mixin title-h1-light;
    }
    &--other {
      @mixin body-regular;
      margin-top: 16px;
    }
  }
  &__quote-author {
    @mixin body-semibold;
    margin-top: 24px;
  }
  &__quote-wrapper + &__paragraph {
    margin-top: 40px;
  }
}

@mixin tablet {
  .a-article {
    .blog-html-block {
      grid-column: 1 / span 12;
    }
    &__header {
      margin-top: -48px;
      background-image: linear-gradient(#ffb81c calc(100% - 96px), #f5f0eb 0%);
      align-items: center;
      &--without-image {
        background: #ffb81c;
        padding-bottom: 96px;
      }
    }
    &__header-text,
    &__header-title {
      padding: 0 48px;
    }
    &__main-image {
      padding: 0 48px;
      img {
        border-radius: 24px;
      }
    }
    &__content {
      column-gap: 24px;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      max-width: 1440px;
      padding: 0 48px;
    }
    &__lead-text {
      grid-column: 1 / span 12;
    }
    .header-text,
    .paragraph,
    &__quote-wrapper {
      grid-column: 4 / span 6;
      padding-left: 0px;
      padding-right: 0px;
    }
    &__simple-image-wrapper {
      grid-column: 4 / span 6;
      .a-article__simple-image {
        border-radius: 24px;
      }
      &--stretched {
        grid-column: 1 / span 12;
      }
    }
  }
}
