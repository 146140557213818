.page-header {
  display: flex;
  flex-direction: column;
  @mixin title-h1-big-light;
  margin-top: 24px;
  padding: 0 16px;
}

@mixin tablet {
  .page-header {
    text-align: center;
    padding: 0 48px;
  }
}

@mixin desktop {
  .page-header {
    margin-top: 48px;
  }
}