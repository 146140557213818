.country-select {
  &__label {
    color: #4a4847;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding-left: 8px;
    position: relative;
    width: 100px;
  }
  &__wrapper {
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:hover {
      .a-main-nav__country-select-value {
        background: linear-gradient(to right, #5085cf, #5085cf);
        background-position: 0px 28px;
        background-repeat: repeat-x;
        background-size: 1px 1px;
        color: #5085cf;
        cursor: pointer;
      }
    }
  }
  &__value {
    color: #4a4847;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    margin-left: 8px;
    padding-bottom: 8px;
    padding-top: 8px;
    position: absolute;
    white-space: nowrap;
    z-index: -1;
    &:after {
      background: transparent url("../images/header/select-arrow.svg") no-repeat
        right 50% top 50%;
      background-size: 16px 20px;
      content: "";
      height: 20px;
      position: absolute;
      right: -20px;
      top: 8px;
      width: 16px;
    }
  }
  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    opacity: 1;
    outline: none;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-top: 8px;
    position: relative;
    text-indent: 1px;
    text-overflow: "";
    width: 100%;
    &::-ms-expand {
      display: none;
    }
    option {
      color: var(--color-gray);
    }
  }
}
