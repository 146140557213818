.voucher {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  &__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__form-undo {
    align-items: center;
    background: none;
    border: none;
    border-radius: 38px;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: center;
    padding: 0px;
    position: absolute;
    right: 0px;
    width: 56px;
    &:hover {
      opacity: 0.8;
    }
  }
  &__form-undo-icon {
    height: 16px;
    position: absolute;
    width: 16px;
    &:before,
    &:after {
      background-color: var(--color-blue);
      content: " ";
      height: 16px;
      left: 8px;
      position: absolute;
      width: 2px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__form-error {
    align-items: center;
    color: rgba(255, 46, 0, 0.8);
    display: flex;
    font-family: var(--font-main);
    font-size: 8px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    line-height: 10px;
    margin-left: 25px;
    margin-top: 4px;
  }
  &__form-value {
    align-items: center;
    background: rgba(9, 87, 195, 0.1);
    border-radius: 38px;
    color: var(--color-blue);
    display: flex;
    font-family: var(--font-main);
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    height: 56px;
    letter-spacing: 0.01em;
    line-height: 20px;
    padding: 0px 24px;
    width: 100%;
  }
  &__form {
    display: flex;
    flex-direction: row;
    position: relative;
    .voucher__form-input {
      background: transparent;
      border: 1px solid rgba(86, 94, 96, 0.15);
      border-radius: 38px;
      color: #666d6d;
      font-family: var(--font-main);
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      height: 56px;
      line-height: 20px;
      outline: none;
      padding: 24px 18px;
      width: 100%;
      &::placeholder {
        color: #838786;
      }
    }
    .secondary-button {
      position: absolute;
      right: 0px;
      z-index: 1;
    }
  }
}
