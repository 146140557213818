.a-list {
  border-top: 1px solid rgba(74, 72, 71, 0.2);
  display: flex;
  flex-direction: column;
  width: 100%;
  &__title {
    cursor: pointer;
    @mixin body-semibold-ui;
    padding: 17px 16px 19px 16px;
    position: relative;
    width: 100%;
    &:after {
      background: transparent url("../images/componets/arrow-down.svg") no-repeat;
      background-size: 16px 20px;
      content: "";
      display: flex;
      height: 20px;
      position: absolute;
      right: 16px;
      top: calc(50% - 10px);
      transition: transform 100ms linear;
      width: 16px;
      z-index: 3;
    }
  }
  &:last-child {
    border-bottom: 1px solid rgba(74, 72, 71, 0.2);
  }
  &__content {
    display: none;
  }
  &--opened {
    .a-list__content {
      display: flex;
      flex-direction: column;
    }
    .a-list__title {
      &:after {
        transform: rotate(180deg);
        transition: transform 100ms linear;
      }
    }
  }
}

@mixin tablet {
  .a-list {
    &__title {
      padding: 17px 48px 19px 48px;
      &:after {
        right: 48px
      }
    }
  }
}