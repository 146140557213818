@import "cart-item.css";
@import "cart-message.css";
@import "cart-show-sad.css";

.cart-show {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 0 16px;
  width: 100%;
  &__line {
    border-bottom: 1px solid #e2ddd9;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__title {
    @mixin title-h1;
    color: #4a4847;
    text-align: center;
  }
  &__items {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  .a-value {
    &--load {
      @mixin value_loader;
    }
    &--new {
      @mixin value_blink;
    }
  }
}

.cart-show-delivery-disclaimer {
  border-radius: 10px;
  padding: 15px;
  background-color: #FF9811;
  color: #fff;
  font-size: 15px;
}

.cart-show-delivery {
  border-bottom: 1px solid #e2ddd9;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 18px;
  &__country {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__text {
    @mixin body-semibold-ui;
    color: #4a4847;
    margin-left: 8px;
  }
  #change-country-form {
    margin-left: auto;
  }
  &__select {
    @mixin body-regular-ui;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    background: url("../images/cart/country-select-arrow.svg") no-repeat;
    background-position: right 0px top 50%;
    border: none;
    color: #0957c3;
    cursor: pointer;
    direction: rtl;
    outline: none;
    padding: 8px 20px;
    position: relative;
    text-align-last: right;
    text-indent: 1px;
    z-index: 2;
    option {
      color: var(--color-gray);
      direction: rtl;
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
  }
  &__terms {
    color: #4a4847;
    display: inline;
    @mixin body-semibold-ui;
    padding-left: 32px;
    width: 100%;
    &--separator,
    &--standard {
      margin-left: 4px;
    }
  }
}

.cart-show-gift {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  &__action {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  &__message {
    @mixin body-regular-ui;
    color: #4a4847;
    margin-top: 2px;
    padding-left: 32px;
    white-space: pre-wrap;
  }
  &__label {
    align-items: center;
    @mixin body-semibold-ui;
    color: #4a4847;
    display: flex;
    height: 32px;
    margin-left: 8px;
  }
  .cart-button {
    margin-left: auto;
  }
  &__form-actions {
    display: flex;
    flex-direction: row;
    .a-button-secondary {
      width: 100%;
    }
  }
  &__form {
    margin-top: 12px;
    form {
      display: flex;
      flex-direction: column;
      .a-button-secondary {
        margin-top: 8px;
      }
    }
  }
  .circle {
    background-color: #f5f0eb;
    border-radius: 100%;
    height: 13px;
    left: 1px;
    position: relative;
    text-align: center;
    top: 1px;
    width: 13px;
  }
  .circle-border {
    background: linear-gradient(90deg, transparent 50%, #c4c0bc 50%),
      linear-gradient(90deg, #c4c0bc 50%, transparent 50%);
    background-color: #0957c3;
    border-radius: 100%;
    height: 15px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;
    width: 15px;
    z-index: 2;
  }
  &__textarea-wrapper {
    display: flex;
    position: relative;
    label {
      @mixin caption-regular;
      background-color: #f5f0eb;
      border-radius: 8px;
      color: #7a7775;
      left: 2px;
      padding-left: 8px;
      padding-top: 6px;
      position: absolute;
      top: 2px;
      width: calc(100% - 4px);
    }
    textarea {
      @mixin body-regular-ui;
      background: none;
      border: 1px solid #e2ddd9;
      border-radius: 8px;
      box-sizing: border-box;
      color: #4a4847;
      min-height: 112px;
      overflow: hidden;
      padding: 28px 40px 24px 10px;
      width: 100%;
    }
  }
}

.cart-result {
  display: flex;
  width: 100%;
  &__wrapper {
    background: #faf8f6;
    border-radius: 24px;
    box-shadow: 0px 2px 6px rgba(125, 130, 130, 0.16);
    margin-top: 18px;
    padding: 16px;
    width: 100%;
  }
  &__line {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .line-title,
    .line-value {
      @mixin body-regular-ui;
      color: #4a4847;
      margin-right: -1px;
      padding: 0px 1px;
    }
    &--discount {
      .line-value {
        color: #ff7500;
        height: 20px;
      }
    }
    &--tax {
      .line-title {
        color: #ff7500;
      }
      .line-value {
        color: #ff7500;
        height: 20px;
      }
    }
    &--vat,
    &--unit {
      .line-value {
        height: 20px;
      }
    }
    &--total {
      border-bottom: 1px solid #efeae6;
      padding-bottom: 18px;
      .line-title,
      .line-value {
        @mixin title-h3-semibold-ui;
      }
      .line-value {
        height: 24px;
      }
    }
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  &__discount {
    border-bottom: 1px solid #efeae6;
    padding-bottom: 18px;
  }
  &__checkout-form {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    .action-button-wrapper {
      margin-top: 16px;
      width: 100%;
      .a-button-primary {
        width: 100%;
      }
    }
  }
}

@mixin tablet {
  .cart-show {
    padding: 0 48px;
    align-items: center;
    &__wrapper {
      width: 100%;
      max-width: 928px;
    }
    &__title {
      text-align: left;
    }
    &__items {
      margin-top: 16px;
    }
  }
  .cart-show-delivery {
    padding-bottom: 10px;
    &__country {
      flex-wrap: nowrap;
    }
    #change-country-form {
      order: 3;
    }
    &__wrapper {
      order: 2;
      width: auto;
      margin-left: 4px;
    }
    &__terms {
      padding-left: 0px;
    }
    &__text {
      &:after {
        content: ":";
      }
    }
    &__text,
    svg {
      flex-shrink: 0;
    }
  }
}

@mixin tablet {
  .cart-show-gift {
    &__form {
      width: 452px;
      .a-button-secondary {
        width: auto;
      }
      .cart-button {
        padding: 18px 32px;
        margin-top: 8px;
        margin-left: 0px;
        &--desktop {
          display: flex;
        }
      }
    }
    .cart-button {
      &--edit {
        display: none;
      }
    }
  }
}

@mixin tablet {
  .cart-result {
    &__checkout-form {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      &__email {
        margin-bottom: 0px;
        width: 452px;
        flex-shrink: 0;
      }
      .action-button-wrapper {
        margin-left: 16px;
        flex-grow: 1;
      }
    }
  }
}

@mixin desktop-big {
  .cart-show {
    max-width: 1024px;
  }
  .cart-show-bottom-block {
    max-width: 302px;
    width: 302px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
