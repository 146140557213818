.title-h1-light {
  &--collection {
    color: #4a4847;
    margin-top: 16px;
    padding: 0 16px;
  }
}
.products-list {
  &__products {
    display: flex;
    flex-direction: column;
    order: 3;
  }
  &__info {
    color: #4a4847;
    margin-bottom: 64px;
    margin-top: 24px;
    order: 4;
    padding: 0 16px;
  }
  &__product-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
  }
  &__first-column-text,
  &__second-column-text {
    order: 3;
  }
  &__features {
    display: block;
    @mixin body-regular;
    margin-top: 8px;
  }
  &__features,
  &__first-column-text,
  &__second-column-text {
    color: #4a4847;
    padding: 0 16px;
    a {
      color: #0957c3;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: #7fade3;
      }
    }
  }
  &__second-column-text {
    margin-top: 8px;
  }
  &__first-column-text {
    margin-top: 16px;
  }
}

@mixin tablet {
  .title-h1-light {
    &--collection {
      grid-column: 1 / span 12;
      padding: 0px;
    }
  }
  .products-list {
    &__products {
      padding-top: 24px;
      order: unset;
      display: block;
      column-count: 2;
      width: 100%;
      grid-column: 1 / span 12;
      .products-list__product-card {
        display: inline-block;
        margin-top: 0px;
        width: 100%;
        float: left;
        page-break-inside: avoid;
        break-inside: avoid;
      }
      .products-list__product-card {
        &:nth-child(odd) {
          padding-right: 24px;
        }
        &:nth-child(even) {
          padding-left: 24px;
        }
        &:not(:first-child) {
          .a-product-card__padding {
            padding-top: 96px;
          }
        }
        &--first {
          .a-product-card__padding {
            padding-top: 48px !important;
          }
        }
      }
    }
    &__features {
      grid-column: 1 / span 12;
      padding: 0px;
      order: unset;
    }
    &__first-column-text {
      grid-column: 1 / span 6;
      padding: 0px;
      order: unset;
      margin-top: 16px;
    }
    &__second-column-text {
      grid-column: 7 / span 6;
      padding: 0px;
      order: unset;
      margin-top: 16px;
    }
    &__info {
      order: unset;
      margin-top: auto;
      margin-bottom: 48px;
      &--first {
        grid-column: 7 / span 6;
      }
      &--second {
        padding-top: 48px;
        grid-column: 1 / span 6;
      }
      a {
        color: #0957c3;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: #7fade3;
        }
      }
    }
  }
}

@mixin desktop {
  .title-h1-light {
    &--collection {
      margin-top: 24px;
      grid-column: 1 / span 4;
      padding: 0px;
      grid-row: span 1;
    }
  }
  .products-list {
    &__products {
      padding-top: 80px;
      .products-list__product-card {
        &:nth-child(odd) {
          padding-right: 0px;
        }
        &:nth-child(even) {
          padding-left: 0px;
        }
        &:not(:first-child) {
          .a-product-card__padding {
            padding-top: 224px;
          }
        }
        &--first {
          .a-product-card__padding {
            padding-top: 96px !important;
          }
        }
      }
    }
    &__features {
      grid-column: 1 / span 4;
      grid-row: span 3;
    }
    &__first-column-text {
      grid-column: 5 / span 4;
      grid-row: span 3;
      margin-top: 24px;
    }
    &__second-column-text {
      grid-column: 9 / span 4;
      grid-row: span 3;
      margin-top: 24px;
    }
    &__info {
      &--first {
        margin-bottom: 96px;
      }
      &--second {
        padding-top: 96px;
      }
    }
  }
}
