.country-suggestion-container {
  background: #424242;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  position: relative;
  top: 110px;
  z-index: 9;
}

.country-suggestion-container .country-suggestion-text {
  max-width: 320px;
}

.country-suggestion-item .country-suggestion-form {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 10px;
  line-height: 35px;
}

.country-suggestion-item .country-suggestion-label {
  position: relative;
}

.country-suggestion-item .country-suggestion-label::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 14px;
  width: 16px;
  height: 9px;
  background: url("../images/country_suggestion/arrow.svg") no-repeat;
}

.country-suggestion-item .country-suggestion-select {
  appearance: none;
  border: none;
  outline: none;
  color: #FFFFFF;
  background: #C4C4C440;
  border-radius: 10px;
  width: 220px;
  height: 35px;
  padding: 0 30px 0 10px;
  margin-left: 10px;
}

.country-suggestion-item .country-suggestion-accept {
  height: 35px;
  padding: 0 20px;
}

.country-suggestion-container .country-suggestion-decline {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  background: url("../images/country_suggestion/close.svg") no-repeat;
}

@media (max-width: 767px) {
  .country-suggestion-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .country-suggestion-container .country-suggestion-text {
    max-width: 100%;
    padding-right: 30px;
  }

  .country-suggestion-item .country-suggestion-select {
    width: auto;
  }

  .country-suggestion-container .country-suggestion-decline {
    position: absolute;
    right: 20px;
    top: 25px;
  }
}
