@import "a-main-nav.css";
@import "a-menu-box.css";
@import "country-select.css";

.a-header {
  background-color: #f5f0eb;
  display: flex;
  flex-direction: row;
  height: 40px;
  position: sticky;
  top: 0px;
  transition: background-color 100ms ease-out;
  width: 100%;
  .a-main-nav__item--checkout {
    display: none;
  }
  &--no-sticky {
    position: static;
  }
  &--transparent {
    background-color: transparent;
    .a-menu-box {
      background: #f5f0eb;
    }
    .a-main-nav__list {
      .a-main-nav__item {
        &--shop,
        &--support {
          .a-main-nav__link {
            color: #f5f0eb;
            &:hover {
              background: linear-gradient(to right, #f5f0eb, #f5f0eb);
              background-position: left 8px top 28px;
              background-repeat: no-repeat;
              background-size: calc(100% - 16px) 1px;
              opacity: 0.7;
            }
          }
        }
        &--country {
          &:hover {
            .country-select__value {
              color: #f5f0eb;
            }
            div {
              opacity: 0.7;
            }
          }
        }
        .country-select__value {
          color: #f5f0eb;
        }
        &--shop {
          .a-main-nav__link {
            &:before {
              background: transparent url("../images/header/m-shop-hover.svg")
                no-repeat right 50% top 50%;
            }
          }
        }
        .country-select__value {
          &:after {
            background: transparent
              url("../images/header/select-arrow-white.svg") no-repeat right 50%
              top 50%;
          }
        }
        &--cart {
          .a-main-nav__link {
            &:before {
              background: transparent url("../images/header/m-cart-hover.svg")
                no-repeat right 50% top 50%;
            }
          }
        }
        &--cart-fill {
          .a-main-nav__link {
            color: #4a4847;
            &:before {
              background: transparent url("../images/header/m-cart-default.svg")
                no-repeat right 50% top 50%;
            }
            &:hover {
              color: #f5f0eb;
            }
          }
        }
      }
    }
  }
  &--yellow {
    background-color: #ffb81c;
  }
  &--blog {
    .a-main-nav__list {
      .a-main-nav__item {
        &--logo {
          .a-main-nav__link {
            background: transparent url("../images/header/logo-grey.svg")
              no-repeat left 0px top 50%;
            background-size: 75px 20px;
          }
        }
        &--cart {
          .a-main-nav__link {
            color: #4a4847;
            .link-bg {
              background: #f5f0eb;
            }
            &:before {
              background: transparent url(../images/header/m-cart-default.svg)
                no-repeat right 50% top 50%;
            }
            &:after {
              background: transparent url(../images/header/m-cart-hover.svg)
                no-repeat right 50% top 50%;
            }
            &:hover {
              color: #f5f0eb;
              .link-bg {
                background: #ff7500;
              }
            }
          }
        }
      }
    }
  }
  &--catalog {
    background-color: #f5f0eb;
    position: sticky;
    top: 0px;
  }
  .checkout-text {
    display: none;
  }
  &--checkout {
    .a-main-nav__list {
      justify-content: flex-start;
      padding-right: 16px;
      padding-top: 4px;
    }
    .a-main-nav__item--checkout {
      display: flex;
    }
    .a-main-nav__item--shop,
    .a-main-nav__item--support,
    .a-main-nav__item--country,
    .a-main-nav__item--search,
    .a-main-nav__item--user {
      display: none;
    }
    .a-main-nav__item--logo {
      margin-left: -8px;
      margin-right: 0px;
      margin-top: 5px;
      position: relative;
      right: auto;
      top: auto;
      .a-main-nav__link {
        background-position: right 50% top 4px;
      }
    }
    .a-main-nav__item--cart {
      margin-left: auto;
      .cart-text {
        display: none;
      }
      .a-main-nav__link {
        background: none;
        display: flex;
        @mixin link-primary;
        height: auto;
        justify-content: center;
        margin-right: -8px;
        padding: 8px;
        width: auto;
        &:after,
        &:before {
          content: none;
        }
        .link-bg {
          display: none;
        }
        .back-text {
          display: none;
        }
        .checkout-text {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .a-main-nav__item--cart-more-10 {
      .a-main-nav__link {
        font-size: 13px;
      }
    }
  }
}
.a-header--transparent.a-header--active {
  .a-menu-box {
    background: #f5f0eb;
  }
  .a-main-nav__list {
    .a-main-nav__item {
      .country-select__value {
        color: #4a4847;
        &:after {
          background: transparent url("../images/header/select-arrow.svg")
            no-repeat right 50% top 50%;
        }
      }
      &--country {
        &:hover {
          .country-select__value {
            color: #5085cf;
          }
          div {
            opacity: 1;
          }
        }
      }
      &--shop,
      &--support {
        .a-main-nav__link {
          color: #4a4847;

          &:hover {
            background: linear-gradient(to right, #5085cf, #5085cf);
            background-position: left 8px top 28px;
            background-repeat: no-repeat;
            background-size: calc(100% - 16px) 1px;
            color: #5085cf;
          }
        }
      }
    }
  }
}
@mixin tablet {
  .shadow-background {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: -100vw;
    background: #4a4847;
    opacity: 0;
    transition: opacity 120ms ease-out;
    &--show {
      opacity: 0.7;
      left: 0px;
      transition: opacity 240ms ease-in;
      display: flex;
    }
  }
  .a-header {
    height: 48px;
    &--transparent {
      .a-main-nav__list {
        .a-main-nav__item {
          &--cart {
            .a-main-nav__link {
              &:before {
                background: transparent url("../images/header/d-cart-hover.svg")
                  no-repeat right 50% top 50%;
              }
            }
          }
          &--cart-fill {
            .a-main-nav__link {
              &:before {
                background: transparent
                  url("../images/header/d-cart-default.svg") no-repeat right 50%
                  top 50%;
              }
            }
          }
        }
      }
    }
    .a-menu-box {
      &__item {
        &:hover {
          .a-menu-box__image--beige {
            opacity: 1;
          }
          .a-menu-box__image--yellow {
            opacity: 0;
          }
        }
      }
    }
    &--yellow {
      .a-menu-box {
        &__item {
          &:hover {
            .a-menu-box__image--yellow {
              opacity: 1;
            }
            .a-menu-box__image--beige {
              opacity: 0;
            }
          }
        }
      }
    }
    &--active {
      .a-menu-box {
        left: -48px;
      }
      .a-main-nav__list {
        .a-main-nav__item {
          &--country {
            z-index: 30;
          }
        }
      }
    }
    &--blog {
      .a-main-nav__list {
        .a-main-nav__item {
          &--logo {
            .a-main-nav__link {
              background: transparent url("../images/header/logo-grey.svg")
                no-repeat right 50% top 50%;
              background-size: 130px 35px;
            }
          }
          &--cart {
            .a-main-nav__link {
              &:before {
                background: transparent url(../images/header/d-cart-default.svg)
                  no-repeat right 50% top 50%;
              }
              &:after {
                background: transparent url(../images/header/d-cart-hover.svg)
                  no-repeat right 50% top 50%;
              }
            }
          }
        }
      }
    }
    &--checkout {
      height: 56px;
    }
    .a-main-nav__list {
      padding-top: 0px;
    }
    .a-main-nav {
      &__item {
        &--search,
        &--user,
        &--cart {
          align-self: center;
        }
      }
    }
    .a-main-nav__item--logo {
      margin-left: -8px;
    }
    .a-main-nav__item--checkout {
      margin-top: 10px;
    }
    .a-main-nav__item--cart {
      .a-main-nav__link {
        .back-text {
          display: flex;
        }
      }
    }
  }
}
