.a-product {
  &__select-colors {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 74px;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 12px;
    width: 100%;
  }
  &__text-after-button {
    color: #4a4847;
    display: flex;
    flex-direction: row;
    @mixin caption-semibold;
    justify-content: center;
    margin-top: 14px;
    .dot {
      color: #aca8a5;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}
.a-color {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 64px;
  outline: none;
  padding: 0px;
  position: relative;
  width: 56px;
  img {
    height: 40px;
    margin-top: 4px;
    transition: all 100ms ease-out;
    width: 40px;
  }
  &__text {
    bottom: -12px;
    color: #7a7775;
    display: none;
    position: absolute;
    @mixin caption-regular;
    text-transform: capitalize;
  }
  &--active {
    .a-color__text {
      display: block;
    }
    img {
      height: 48px;
      margin-top: 0px;
      width: 48px;
    }
    &:after {
      border: 1px solid rgba(74, 72, 71, 0.2);
      border-radius: 32px;
      content: "";
      height: 64px;
      left: -4px;
      position: absolute;
      top: -8px;
      width: 64px;
    }
  }
  &--out-of-stock {
    picture {
      opacity: 0.5;
    }
    &:before {
      border-bottom: 1px solid #ff7500;
      content: "";
      height: 40px;
      position: absolute;
      -webkit-transform: translateY(-10px) translateX(14px) rotate(45deg);
      width: 64px;
      z-index: 3;
    }
  }
  &--future-delivery {
    &:before {
      content: "";
      background: url("../images/products/clock.svg") no-repeat center;
      color: #4A4847;
      height: 24px;
      width: 24px;
      text-align: center;
      position: absolute;
      z-index: 3;
      background-color: #fff;
      border-radius: 12px;
      right: -3px;
      top: -12px;
    }
  }
  &:hover {
    img {
      height: 48px;
      margin-top: 0px;
      transition: all 100ms ease-out;
      width: 48px;
    }
  }
}
