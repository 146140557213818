.a-menu-box {
  background: #f5f0eb;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0px;
  min-height: 100vh;
  padding: 0px 16px 16px;
  position: absolute;
  top: 0px;
  transition: left 120ms ease-out;
  width: 100%;
  &--hide {
    left: -100vw;
    transition: left 120ms ease-in;
  }
  &__image {
    display: none;
  }
  &__close {
    background: none;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    height: 40px;
    margin-left: auto;
    margin-right: -16px;
    outline: none;
    padding: 12px;
    width: 40px;
    &:hover,
    &:active,
    &:focus {
      background: #afc2df;
    }
  }
  &__list {
    color: #4a4847;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    list-style-type: none;
    margin: 0px;
    padding: 0px 0px 40px 0px;
  }
  &__image {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
  &__item-details {
    @mixin caption-regular;
    margin: 4px 0px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    max-width: 240px;
    &:before {
      content: none;
    }
    &:first-child {
      margin-top: 4px;
    }
    &--bottom-start {
      margin-top: 40px;
    }
    &--country {
      margin-left: -8px;
      margin-top: 6px;
    }
    &--category {
      a {
        @mixin title-h2;
        line-height: 27px;
      }
    }
    &--link {
      a {
        @mixin body-regular-ui;
        line-height: 23px;
      }
    }
    a {
      color: #4a4847;
      margin-left: -4px;
      outline: none;
      padding: 4px;
      text-decoration: none;
      &:hover {
        color: #5085cf;

        span {
          border-bottom: 1px solid #5085cf;
        }
      }

      &:active {
        color: #0957c3;
        span {
          border-bottom: 1px solid #0957c3;
        }
      }

      &:focus {
        color: #ff7500;

        span {
          border-bottom: 1px solid #ff7500;
        }
      }
    }
  }
}

@mixin tablet {
  .a-menu-box {
    background: inherit;
    width: 444px;
    height: calc(100vh - 48px);
    top: 0px;
    left: -496px;
    padding-top: 48px;
    padding-right: 48px;
    &__list {
      paddinb-bottom: 0px;
      padding-left: 32px;
      margin-top: 48px;
    }
    &__image {
      display: flex;
      position: absolute;
      bottom: 0px;
      right: 0px;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    &__item {
      max-width: none;
      &--country {
        display: none;
      }
      &--category {
        a {
          line-height: 43px;
        }
      }
    }
    &__item-details {
      &:empty {
        display: none;
      }
    }
    &__close {
      display: none;
    }
  }
}
