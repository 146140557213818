.sad-popup__content {
  bottom: 0px;
  bottom: calc(env(safe-area-inset-bottom) - 30px);
  box-shadow: 0px 2px 6px rgba(125, 130, 130, 0.16);
  p {
    @mixin body-regular;
    color: #4a4847;
    margin-block-start: 8px;
  }
}
.sad-popup__button {
  &:after {
    background: #aca8a5 url("../images/cart/sad-info.svg") no-repeat right 7px
      top 3px;
    border-radius: 10px;
    content: "";
    height: 20px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 20px;
  }
  &:focus,
  &:active {
    &:after {
      background-color: #4a4847;
    }
  }
}
@mixin tablet {
  .sad-popup {
    width: 344px;
  }
}
