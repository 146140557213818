.header-text {
  margin-top: 40px;
  @mixin title-h2;
  padding: 0 16px;
}

@mixin tablet {
  .header-text {
    max-width: 452px;
    width: 100%;
    align-self: center;
    padding: 0px;
  }
}

@mixin desktop {
  .header-text{
    max-width: 660px;
  }
}