@define-mixin value_loader {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #efeae6;
  background: linear-gradient(to right, #efeae6 8%, #d3cfcb 18%, #efeae6 33%);
  background-size: 800px 80px;
  border-radius: 4px;
  color: var(--color-beige);
  position: relative;
  width: 40px;
}
@define-mixin value_blink {
  animation: blink 4s linear;
  border-radius: 4px;
}

@keyframes blink {
  0% {
    background: rgba(249, 232, 199, 1);
    animation-timing-function: steps(1, end);
  }
  20% {
    background: rgba(249, 232, 199, 0.8);
  }
  40% {
    background: rgba(249, 232, 199, 0.6);
  }
  60% {
    background: rgba(249, 232, 199, 0.4);
  }
  80% {
    background: rgba(249, 232, 199, 0.2);
  }
  100% {
    background: rgba(249, 232, 199, 0);
  }
}

@define-mixin mobile {
  @media (max-width: 767px) {
    @mixin-content;
  }
}
@define-mixin tablet {
  @media (min-width: 768px) {
    @mixin-content;
  }
}
@define-mixin desktop {
  @media (min-width: 1024px) {
    @mixin-content;
  }
}

@define-mixin desktop-big {
  @media (min-width: 1440px) {
    @mixin-content;
  }
}
