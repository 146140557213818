.add-to-cart {
  &__goto {
    align-items: center;
    background: #ffb81c;
    border: none;
    border-radius: 20px;
    color: #4a4847;
    cursor: pointer;
    flex-direction: column;
    height: 40px;
    outline: none;
    position: relative;
    text-decoration: none;
    &:after {
      background: transparent url("../images/header/m-cart-default.svg")
        no-repeat right 50% top 50%;
      background-size: 24px 24px;
      content: "";
      height: 24px;
      left: 8px;
      position: absolute;
      top: 7px;
      width: 24px;
      z-index: 2;
    }
    &:hover {
      background: #ff7500;
      color: #f5f0eb;
      &:after {
        background: transparent url("../images/header/m-cart-hover.svg")
          no-repeat right 50% top 50%;
      }
      &:before {
        background: #ff7500;
      }
    }
    &:active,
    &:focus {
      background: #ff7500;
      color: #f5f0eb;
    }
  }
  &__goto-count {
    color: inherit;
    font-family: var(--font-main);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    padding-top: 11px;
  }
  &__goto-text {
    display: none;
  }
  &__button {
    align-content: center;
    align-items: center;
    background-color: #0957c3;
    border: none;
    border-radius: 20px;
    color: #f5f0eb;
    cursor: pointer;
    height: 40px;
    justify-content: center;
    outline: none;
    position: relative;
    z-index: 2;
    .button-text {
      display: none;
    }
    .lds-spinner {
      display: none;
    }
    &--not-available {
      background-color: transparent;
      border: 1px solid rgba(74, 72, 71, 0.2);
      color: rgba(74, 72, 71, 0.2);
      pointer-events: none;
    }
    &--loading {
      background: #7fade3;
      padding: 0px;
      &:hover,
      &:active,
      &:focus {
        background: #7fade3;
      }
      .lds-spinner {
        color: black;
        display: inline-block;
        height: 80px;
        left: -20px;
        position: absolute;
        top: -20px;
        transform: scale(0.4);
        width: 80px;
      }
      .button-text,
      .button-icon {
        display: none;
      }
    }
  }
  &__wrapper {
    &--catalog {
      .add-to-cart {
        &__button {
          &:disabled {
            background: none;
            pointer-events: none;
            border: 1px solid rgba(74, 72, 71, 0.2);
            color: rgba(74, 72, 71, 0.2);
            cursor: default;
          }
          &--loading {
            background: #7fade3;
            padding: 0px;
            &:hover,
            &:active,
            &:focus {
              background: #7fade3;
            }
          }
        }
      }
    }
  }
  &__wrapper {
    &--product {
      .add-to-cart {
        &__goto {
          @mixin body-semibold-ui;
          border: none;
          border-radius: 84px;
          color: #4a4847;
          height: 56px;
          padding: 18px 32px;
          width: 100%;
          &:after {
            content: none;
          }
          &:hover {
            &:after {
              content: none;
            }
          }
        }
        &__goto-count {
          display: none;
        }
        &__goto-text {
          display: flex;
        }
        &__button {
          height: 56px;
          @mixin a-button-primary;
          .button-text {
            display: flex;
          }
          &--loading {
            .lds-spinner {
              color: black;
              display: inline-block;
              height: 80px;
              left: calc(50% - 40px);
              position: absolute;
              top: -10px;
              transform: scale(0.4);
              width: 80px;
            }
            .button-text {
              color: rgba(0, 0, 0, 0);
            }
          }
          &--hide {
            display: none;
          }
          .button-text {
            &--hide {
              display: none;
            }
          }
        }
      }
    }
  }
}

.lds-spinner div {
  animation: lds-spinner 1.2s linear infinite;
  transform-origin: 40px 40px;
}
.lds-spinner div:after {
  background: white;
  border-radius: 20%;
  content: " ";
  display: block;
  height: 18px;
  left: 37px;
  position: absolute;
  top: 3px;
  width: 6px;
}
.lds-spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0deg);
}
.lds-spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}
.lds-spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotate(60deg);
}
.lds-spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotate(90deg);
}
.lds-spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotate(120deg);
}
.lds-spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotate(150deg);
}
.lds-spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotate(180deg);
}
.lds-spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(210deg);
}
.lds-spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotate(240deg);
}
.lds-spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}
.lds-spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotate(300deg);
}
.lds-spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin tablet {
  .add-to-cart {
    &__goto {
      @mixin body-semibold-ui;
      border: none;
      border-radius: 84px;
      color: #4a4847;
      padding: 18px 32px;
      height: 56px;
      &:after {
        content: none;
      }
      &:hover {
        &:after {
          content: none;
        }
      }
    }
    &__goto-count {
      display: none;
    }
    &__goto-text {
      display: flex;
    }
    &__button {
      height: 56px;
      @mixin a-button-primary;
      .button-text {
        display: flex;
      }
      &--loading {
        .lds-spinner {
          color: black;
          display: inline-block;
          height: 80px;
          left: calc(50% - 40px);
          position: absolute;
          top: -10px;
          transform: scale(0.4);
          width: 80px;
        }
        .button-text {
          color: rgba(0, 0, 0, 0);
        }
      }
      .button-text {
        &--hide {
          display: none;
        }
      }
    }
  }
}
