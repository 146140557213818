@import "a-product.css";
@import "add-to-cart.css";

.a-product-show {
  .add-to-cart__wrapper {
    margin-top: 16px;
    width: 100%;
    .add-to-cart__goto,
    .add-to-cart__button {
      width: 100%;
    }
  }
  &__sticky {
    display: flex;
    flex-direction: column;
  }

  &__feature {
    @mixin body-caps;
    color: #ff7500;
    margin-left: 8px;
  }
  &__main-image {
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 100%;
    position: relative;
    width: 100%;
    img {
      left: 0px;
      position: absolute;
      top: 0px;
      width: 100%;
    }
  }
  &__title {
    @mixin title-h1;
  }
  &__future-delivery {
    background: #e9ded2;
    color: #4a4847;
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 14px;
    margin-top: 10px;
  }
  .variant-image {
    opacity: 1;
    transition: opacity 150ms ease-out;
    &--hide {
      opacity: 0;
      transition: opacity 150ms ease-out;
    }
  }
  .a-button-link {
    padding: 18px 0px;
  }
}
.vimeo-video {
  &--how-to-set {
    iframe {
      border-radius: 1.5em;
    }
  }
}
@mixin tablet {
  .vimeo-video {
    iframe {
      border-radius: 1.5em;
    }
  }
}
