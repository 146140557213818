@import "catalog-menu.css";
@import "catalog-service.css";
@import "product-list.css";
@import "a-product-card.css";
@import "product-item.css";

.collection {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  &__anchor {
    order: 4;
    &--first {
      order: 0;
    }
    &--first-end {
      order: 3;
    }
  }
  &__compare {
    order: 4;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1344px;
    width: 100%;
    .title-h1-big-light {
      display: none;
    }
  }
  &__component {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    max-width: 1344px;
    order: 4;
    width: 100%;
  }
  &__bg-wrapper {
    height: 100vh;
    left: 0;
    opacity: 1;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  &__bg {
    bottom: 0px;
    height: 100%;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: opacity 0.75s ease-out;
    width: 100%;
    &--s1 {
      //background: linear-gradient(180deg, #f5f0eb 0%, #80aee3 100%);
      background: linear-gradient(180deg, #f5f0eb 0%, #7fade3 100%);
      z-index: 1;
    }
    &--s2 {
      //background: #7fade3;
      //background: linear-gradient(180deg, #7fade3 0%, #f5f0eb 100%);
      background: #7fade3;
      opacity: 0;
      z-index: 2;
    }
    &--s3 {
      //background: linear-gradient(180deg, #7fade3 0%, #fcc89b 100%);
      background: #f5f0eb;
      opacity: 0;
      z-index: 3;
    }
    &--s4 {
      //background: #fcc89b;
      background: linear-gradient(180deg, #fcc89b 0%, #7fade3 100%);
      opacity: 0;
      z-index: 4;
    }
    &--s5 {
      //background: linear-gradient(180deg, #fcc89b 0%, #f5f0eb 100%);
      background: #fcc89b;
      opacity: 0;
      z-index: 5;
    }
    &--s6 {
      //background: linear-gradient(180deg, #f5f0eb 0%, #ffb81c 100%);
      background: linear-gradient(180deg, #ffb81c 0%, #f5f0eb 100%);
      opacity: 0;
      z-index: 6;
    }
    &--s7 {
      background: #f5f0eb;
      opacity: 0;
      z-index: 7;
    }
    &--active {
      opacity: 1;
    }
  }
}

@mixin tablet {
  .collection {
    &__component {
      grid-column: 1 / span 12;
      order: unset;
    }
    &__compare,
    &__anchor {
      order: unset;
      grid-column: 1 / span 12;
    }
    &__wrapper,
    &__component {
      grid-auto-flow: row dense;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 24px;
      .title-h1-big-light {
        display: block;
        color: #4a4847;
        margin-top: 40px;
        grid-column: 1 / span 12;
      }
    }
    &__wrapper {
      padding: 0 48px;
    }
  }
}

@mixin desktop {
  .collection {
    &__component {
      margin-top: 88px;
    }
    &__wrapper {
      .title-h1-big-light {
        margin-top: 48px;
      }
    }
  }
}

@mixin desktop-big {
  .collection {
    &__wrapper {
      padding: 0px;
    }
  }
}
