.checkout-item {
  align-items: center;
  border-bottom: 1px solid #efeae6;
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  padding-top: 8px;
  &--available {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  &__warning-message {
    @mixin caption-regular;
    align-items: center;
    color: #ff7500;
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    text-align: right;
    svg {
      margin-right: 2px;
    }
  }
  &__img {
    height: 64px;
    position: relative;
    width: 64px;
    img {
      height: 64px;
      object-fit: contain;
      width: 64px;
    }
  }
  &__quantity {
    @mixin caption-regular;
    align-items: center;
    background: #4a4847;
    border-radius: 12px;
    color: #f5f0eb;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: -2px;
    width: 24px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  &__name {
    @mixin body-semibold-ui;
    color: #4a4847;
  }
  &__descriptions {
    display: flex;
    flex-direction: row;
  }
  &__future-delivery {
    background: #e9ded2;
    color: #4a4847;
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 14px;
    margin-top: 5px;
  }
  &__price {
    @mixin body-semibold-ui;
    color: #4a4847;
  }
  &__properties {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    &--dot {
      color: #aca8a5;
      @mixin body-regular-ui;
      margin-left: 4px;
    }
    &--color-text {
      @mixin body-regular-ui;
      margin-left: 4px;
    }
    &--color-value {
      @mixin body-regular-ui;
      margin-left: 4px;
    }
    &--color-circle {
      border-radius: 4px;
      height: 8px;
      margin-left: 4px;
      width: 8px;
    }
  }
}
