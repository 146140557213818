.a-product-card {
  color: #4a4847;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  order: 4;
  width: 100%;
  .add-to-cart__button {
    width: 40px;
  }
  &--first {
    order: 1;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
  }
  &__main-img {
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    .default-picture,
    .hover-picture {
      align-items: center;
      flex-direction: column;
      padding: 0px;
      width: 100%;
    }
    picture {
      height: 100%;
      max-height: 100%;
    }
    img {
      max-height: 100%;
      object-fit: contain;
    }
    .hover-picture {
      img {
        border-radius: 24px;
      }
    }
    &--animate-right {
      div {
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-name: image_animate;
        animation-play-state: running;
        animation-timing-function: ease-in-out;
      }
    }
  }
  &__feature {
    @mixin caption-caps;
    color: #ff7500;
    margin-top: 8px;
  }
  &__name {
    @mixin title-h2;
    padding-right: 48px;
  }
  &__price {
    @mixin title-h3-light-ui;
  }
  &__details {
    margin-top: 8px;
    @mixin body-regular;
  }
  &__review {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  &__star {
    background: #ff7500;
    display: flex;
    height: 22px;
    margin-right: 2px;
    mask-image: url("../images/catalog/star.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 24px 22px;
    width: 24px;
  }
  &__review-count {
    @mixin caption-caps;
    color: #4a4847;
  }
  .add-to-cart__wrapper {
    position: absolute;
    right: 8px;
    top: 28px;
  }
}

@keyframes image_animate {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-48px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 767px) {
  .a-product-card {
    &__main-img {
      padding: 0 16px;
    }
  }
}

@mixin tablet {
  .a-product-card {
    max-height: calc(100vh - 110px);
    order: unset;
    margin-top: 0px;
    .add-to-cart__button {
      width: auto;
    }
    .swiper-wrapper {
      height: auto;
    }
    &--first {
      margin-top: 24px;
      margin-bottom: 32px;
      grid-column: 4 / span 6;
    }
    .add-to-cart__wrapper {
      position: relative;
      width: 100%;
      justify-content: center;
      top: 0px;
      right: 0px;
      margin-top: 24px;
    }
    &__feature {
      text-align: center;
      margin-top: 16px;
    }
    &__content {
      padding: 0px;
      align-items: center;
    }
    &__price {
      display: none;
    }
    &__name,
    &__details {
      padding-right: 0px;
      text-align: center;
    }
    &__main-img {
      display: flex;
      cursor: pointer !important;
      .default-picture {
        opacity: 1;
        transition: opacity 300ms ease-out;
      }
      .hover-picture {
        opacity: 0;
        display: flex;
        transition: opacity 300ms ease-out;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
      }
      &:hover {
        .hover-picture {
          opacity: 1;
          width: 100%;
          transition: opacity 300ms ease-out;
        }
        .default-picture {
          opacity: 0;
          transition: opacity 300ms ease-out;
        }
      }
      &--without-hover {
        &:hover {
          .hover-picture {
            opacity: 0;
            width: 100%;
            transition: opacity 300ms ease-out;
          }
          .default-picture {
            opacity: 1;
            transition: opacity 300ms ease-out;
          }
        }
      }
    }
  }
}

@mixin desktop {
  .a-product-card {
    &__name,
    &__details {
      padding: 0 48px;
    }
  }
}
