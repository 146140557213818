.checkout-show-result {
  background: #faf8f6;
  border-radius: 24px;
  box-shadow: 0px 2px 6px rgba(125, 130, 130, 0.16);
  margin-top: 40px;
  padding: 16px;
  width: 100%;
  .a-button-primary {
    margin-top: 16px;
    width: 100%;
  }
}
