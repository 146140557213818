@import "checkout-show-result.css";
@import "checkout-item.css";
@import "shipping-address-result.css";
@import "checkout-total.css";
@import "checkout-invalid-fields.css";

#checkout-form-frame {
  width: 100%
}

.checkout-show {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 0px 16px;
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 928px;
    width: 100%;
  }
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__title {
    text-align: left;
    @mixin title-h3-semibold-ui;
  }
  .a-input,
  .a-select-input,
  .a-radio-button-groups,
  .a-textarea {
    margin-top: 8px;
  }
  .a-checkbox-input {
    margin-top: 16px;
  }
  &__button-optional {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: -8px;
    svg {
      margin-right: 4px;
    }
    &--hide {
      display: none;
    }
  }
  &__button-optional-text {
    color: #aca8a5;
    margin-left: 4px;
  }
  &__shipping-address,
  &__contact,
  &__delivery-method,
  &__billing-address {
    margin-top: 24px;
  }
  &__delivery-method {
    .delivery-info {
      display: flex;
      flex-direction: column;
      margin: 0 8px;
      &__term {
        @mixin body-regular-ui;
        color: #4a4847;
      }
      &__name {
        @mixin caption-regular;
        color: #7a7775;
      }
      &__price {
        color: #4a4847;
        @mixin body-regular-ui;
        margin-left: auto;
        white-space: nowrap;
      }
    }
  }
  &__help-block {
    border-bottom: 1px solid #e2ddd9;
    @mixin body-regular-ui;
    border-top: 1px solid #e2ddd9;
    color: #4a4847;
    display: inline-flex;
    justify-content: center;
    margin-top: 40px;
    padding: 16px 0px;
    text-align: center;
    a {
      @mixin link-primary;
    }
  }
  .a-value {
    &--load {
      @mixin value_loader;
      width: auto;
    }
  }
}

@mixin tablet {
  .checkout-show {
    padding: 0 48px;

    &__form-wrapper {
      flex-shrink: 0;
      max-width: 452px;
    }
  }
}

@mixin desktop-big {
  .checkout-show {
    max-width: 1024px;
    &__help-block {
      margin-bottom: 96px;
    }
  }
  .checkout-show-bottom-block {
    max-width: 302px;
    width: 302px;
  }
}
