.shipping-address-result {
  border-bottom: 1px solid #efeae6;
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  padding-top: 10px;
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .a-button-small {
      margin-right: -8px;
    }
  }
  &__title {
    color: #4a4847;
    @mixin body-semibold-ui;
    margin-top: 8px;
  }
  &__address {
    color: #4a4847;
    @mixin body-regular-ui;
  }
}
