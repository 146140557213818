.cart-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-bottom: unset;
  background-color: rgb(250, 248, 246);
  box-shadow: 0.139px 0.99px 0px 0px rgba(237, 232, 227, 0.004),0.139px 0.99px 5px 0px rgba(231, 225, 218, 0.004);
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  &.discounted {
    background-position: bottom right;
    background-repeat: no-repeat;
  }
  &__wrapper {
    width: 100%;
  }
  &__added,
  &__removed {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    &--hide {
      display: none;
    }
  }
  &__info-and-actions {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &__descriptions {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &__img {
    height: 112px;
    width: 112px;
    img {
      height: 112px;
      object-fit: contain;
      width: 112px;
      border-radius: 10px;
      border: 1px solid rgb(221 221 221 / 43%);
    }
  }
  &__warning-message {
    @mixin body-regular-ui;
    align-items: center;
    color: #ff7500;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    text-align: right;
  }
  &__name {
    @mixin title-h3-semibold-ui;
    color: #4a4847;
    margin-top: 8px;
    text-align: center;
  }
  &__color {
    @mixin caption-regular;
    align-items: baseline;
    color: #7a7775;
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    &--value {
      @mixin caption-semibold;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: 4px;
    }
  }
  &__discount-title {
    color: var(--color-orange);
    font-size: 13px;
  }
  &__future-delivery {
    background: #e9ded2;
    color: #4a4847;
    padding: 8px 16px;
    border-radius: 16px;
    font-size: 14px;
    position: absolute;
    bottom: 14px;
  }
  &__color-circle {
    border-radius: 4px;
    height: 8px;
    margin-left: 4px;
    width: 8px;
  }
  &__actions {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    width: 100%;
  }
  &__action-quantity {
    @mixin title-h3-semibold-ui;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../images/cart/action-quantity-arrow.svg") no-repeat;
    background-position: right 6px top 50%;
    border: none;
    color: #4a4847;
    direction: rtl;
    margin-top: -6px;
    outline: none;
    padding: 6px 32px 6px 0px;
    text-align-last: right;
    text-indent: 1px;
    text-overflow: "";
    width: 70px;
    option {
      color: var(--color-gray);
      direction: rtl;
    }
  }
  &__action-quantity::-ms-expand {
    display: none;
  }
  &__price-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
  &__price-total {
    @mixin title-h3-semibold-ui;
    color: #4a4847;
    height: 24px;
    text-align: right;
  }
  &__price-save {
    @mixin caption-regular;
    height: 20px;
    margin-top: 6px;
    text-align: right;
    white-space: nowrap;
    &--old {
      background-image: linear-gradient(180deg, #7a7775 0, #7a7775);
      background-position: 0px 9px;
      background-repeat: repeat-x;
      background-size: 1px 1px;
      color: #7a7775;
      padding-left: 1px;
    }
    &--text,
    &--discount {
      color: #ff7500;
      margin-left: 4px;
    }
  }
  &__price-per-unit {
    @mixin caption-regular;
    color: #7a7775;
    height: 20px;
    margin-top: 6px;
    text-align: right;
    &--hide {
      display: none;
    }
    &--text {
      margin-left: 4px;
    }
  }
  &__action-remove-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__removed {
    .cart-item__img {
      height: 64px;
      margin-bottom: 8px;
      width: 64px;
      img {
        height: 64px;
        width: 64px;
      }
    }
    #return-item-form {
      width: 100%;
    }
    .a-button-secondary {
      margin-bottom: 8px;
      margin-top: 16px;
      width: 100%;
    }
  }
  &__removed-text {
    @mixin body-regular-ui;
    color: #aca8a5;
    text-align: center;
    width: 100%;
  }
  &__added {
    &--not-available {
      .cart-item__name {
        color: #aca8a5;
      }
      .cart-item__img {
        opacity: 0.5;
      }
      .cart-item__info-and-actions {
        width: 100%;
      }
      .cart-item__actions {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        width: 100%;
        .a-button-secondary,
        .a-button-primary {
          margin-top: 16px;
          width: 100%;
        }
      }
      #remove-item-form {
        margin-top: 10px;
      }
    }
  }
}

@mixin tablet {
  .cart-item {
    &__removed {
      flex-direction: row;
      align-items: center;
      &--hide {
        display: none;
      }
      .cart-item__img {
        margin-bottom: 0px;
      }
      #return-item-form {
        width: auto;
        margin-left: auto;
      }
    }
    &__descriptions {
      align-items: flex-start;
      margin-left: 16px;
      flex-shrink: 0;
    }
    &__name {
      text-align: left;
    }
    &__removed-text {
      text-align: left;
      margin-left: 16px;
      width: auto;
      flex-shrink: 0;
      &:nth-child(3) {
        margin-left: 4px;
      }
    }
    &__actions {
      flex-grow: 1;
      justify-content: flex-end;
      margin-top: 0px;
    }
    &__price-wrapper {
      margin-left: 16px;
      width: 140px;
    }
    &__added {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      &--not-available {
        display: grid;
        grid-template-columns: 112px max-content 1fr;
        grid-template-rows: 24px 1fr;
        grid-column-gap: 16px;
        padding-bottom: 8px;
        .cart-item__img {
          grid-column: 1 / span 1;
          grid-row: 1 / span 2;
        }
        .cart-item__name {
          margin-top: 0px;
        }
        #remove-item-form {
          margin-top: 0px;
        }
        .cart-item__info-and-actions {
          grid-column: 2 / span 1;
          grid-row: 1 / span 2;
          margin-top: 0px;
          align-items: flex-start;
          min-height: 112px;
          .cart-item__actions {
            margin-top: auto;
            flex-direction: row;
            align-items: flex-end;
            .a-button-primary,
            .a-button-secondary {
              flex-shrink: 0;
              width: auto;
              margin-top: 0px;
            }
            .a-button-secondary {
              margin-left: 16px;
            }
          }
        }
        .cart-item__warning-message {
          grid-column: 3 / span 1;
          grid-row: 1 / span 1;
          align-self: flex-end;
          margin-top: 0px;
          margin-left: auto;
          height: 20px;
        }
        #remove-item-form {
          grid-column: 3 / span 1;
          grid-row: 2 / span 1;
          align-self: flex-start;
        }
      }
      &--hide {
        display: none;
      }
    }
  }
}
