.cart-button {
  @mixin body-regular-ui;
  align-items: center;
  background: none;
  border: none;
  color: #0957c3;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: -6px;
  padding: 6px;
  text-align: right;
  &:hover,
  &:active,
  &:focus {
    color: #6794d3;
  }
  &--desktop {
    display: none;
  }
}
.a-button-small {
  @mixin caption-regular;
  background: none;
  border: none;
  color: #0957c3;
  cursor: pointer;
  flex-shrink: 0;
  outline: none;
  padding: 8px;
  text-decoration: none;
  &:hover,
  &:active {
    color: #6794d3;
  }
  &--hide {
    display: none;
  }
}
.a-button-secondary {
  @mixin body-semibold-ui;
  align-items: center;
  background: none;
  border: 1px solid #afc2df;
  border-radius: 76px;
  box-sizing: border-box;
  color: #0957c3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  padding: 17px 32px;
  &:hover {
    background: #dee1e7;
    border: 1px solid #dee1e7;
  }
  &:active {
    background: #afc2df;
    border: 1px solid #afc2df;
  }
  &:disabled {
    background: none;
    border: 1px solid rgba(74, 72, 71, 0.2);
    color: rgba(74, 72, 71, 0.2);
  }
}
@define-mixin a-button-primary {
  @mixin body-semibold-ui;
  align-items: center;
  background: linear-gradient(
    272.59deg,
    #0957c3 0%,
    #0957c3 42.19%,
    #5085cf 74.48%,
    #81acdb 99.44%
  );
  border: none;
  border-radius: 84px;
  color: #f5f0eb;
  cursor: pointer;
  height: 56px;
  justify-content: center;
  outline: none;
  padding: 18px 32px;
  text-align: center;
  text-decoration: none;
  &:hover {
    background: linear-gradient(
      272.59deg,
      #0957c3 0%,
      #0957c3 16.15%,
      #81acdb 73.44%
    );
  }
  &:active {
    background: linear-gradient(272.59deg, #5085cf 0%, #81acdb 100%);
    border-radius: 84px;
  }
  &:disabled,
  &--disabled {
    background: none;
    pointer-events: none;
    border: 1px solid rgba(74, 72, 71, 0.2);
    color: rgba(74, 72, 71, 0.2);
    cursor: default;
  }
}

.a-button-primary {
  @mixin a-button-primary;
}

.a-button-combo-on-yellow {
  @mixin body-semibold-ui;
  align-items: center;
  background: #faf8f6;
  border: 1px solid #faf8f6;
  border-radius: 40px;
  box-sizing: border-box;
  color: #ff7500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 17px 32px;
  text-align: center;
  &:hover {
    background: #f9e8c7;
    border: 1px solid #f9e8c7;
  }
  &:active {
    background: #f9ecd6;
    border: 1px solid #f9ecd6;
  }
  &:disabled {
    background: transparent;
    border: 1px solid #ffe7b3;
    color: #ffe7b3;
    cursor: default;
  }
}

.a-button-combo-on-white {
  @mixin body-semibold-ui;
  align-items: center;
  background: #faf8f6;
  border: 1px solid #afc2df;
  border-radius: 40px;
  box-sizing: border-box;
  color: #0957c3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 17px 32px;
  text-align: center;
  &:hover {
    background: #dee1e7;
    border: 1px solid #dee1e7;
  }
  &:active {
    background: #afc2df;
    border: 1px solid #afc2df;
  }
  &:disabled {
    background: #f5f0eb;
    border: 1px solid #e2ddd9;
    color: #c4c0bc;
    cursor: default;
    opacity: 1;
  }
}

@keyframes animation_required {
  0% {
    background-color: #ffe7b3;
    border: 1px solid #ffb81c;
    padding: 28px 56px 11px 10px;
  }
  100.0% {
    background-color: #f5f0eb;
    border: 1px solid #e2ddd9;
    padding: 28px 56px 11px 10px;
  }
}

.a-input {
  display: flex;
  flex-direction: row;
  position: relative;
  input {
    @mixin body-regular-ui;
    align-items: center;
    appearance: none;
    background: #f5f0eb;
    border: 1px solid #e2ddd9;
    border-radius: 8px;
    box-sizing: border-box;
    color: #4a4847;
    display: flex;
    height: 56px;
    outline: none;
    padding: 28px 10px 11px 10px;
    width: 100%;
    &:focus {
      border: 2px solid #ffb81c;
      padding: 27px 55px 10px 9px;
    }
  }
  .field-required {
    animation-duration: 2.5s;
    animation-iteration-count: 1;
    animation-name: animation_required;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
  }
  label {
    @mixin caption-regular;
    align-items: center;
    color: #7a7775;
    display: flex;
    left: 10px;
    position: absolute;
    top: 8px;
    .warning-icon {
      margin-right: 4px;
    }
  }
  &--error {
    input {
      border: 2px solid #ff7500;
      &:focus {
        border: 2px solid #ff7500;
      }
    }
    label {
      color: #ff7500;
    }
  }
  &--with-hide-button {
    input {
      flex-grow: 1;
      margin-right: 8px;
      width: auto;
    }
    .a-button-small {
      margin-right: -8px;
    }
  }
  &--hide {
    display: none;
  }
  &--yellow {
    border-color: #ffe7b3;
    input {
      background: #ffb81c;
      border: 1px solid #ffe7b3 !important;
      &::placeholder {
        color: #ffd985;
      }
      &:focus {
        border: 2px solid #ffe7b3 !important;
        padding: 28px 10px 11px 9px !important;
      }
    }
    label {
      color: #faf8f6;
    }
  }
}

.a-select-input {
  display: flex;
  flex-direction: row;
  position: relative;
  select {
    align-items: center;
    @mixin body-regular-ui;
    appearance: none;
    background: #f5f0eb;
    background: none;
    background: url("../images/checkout/arrow.svg") no-repeat;
    background-position: right 12px top 50%;
    border: 1px solid #e2ddd9;
    border-radius: 8px;
    box-sizing: border-box;
    color: #4a4847;
    display: flex;

    height: 56px;
    outline: none;
    padding: 20px 56px 0px 10px;
    text-overflow: "";
    width: 100%;
    z-index: 1;
    &:focus {
      border: 1px solid #e2ddd9;
    }
    &::-ms-expand {
      display: none;
    }
  }
  label {
    @mixin caption-regular;
    align-items: center;
    color: #7a7775;
    display: flex;
    left: 10px;
    position: absolute;
    top: 8px;
    .warning-icon {
      margin-right: 4px;
    }
  }
}

.a-checkbox-input {
  &__label {
    @mixin body-regular-ui;
    appearance: none;
    color: #4a4847;
    cursor: pointer;
    display: block;
    padding-left: 28px;
    padding-right: 36px;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &:hover input ~ .checkmark {
    }

    .checkmark {
      background: none;
      border: 1px solid #afc2df;
      border-radius: 4px;
      box-sizing: border-box;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;

      &:checked ~ .checkmark {
        background: #ffb81c url("../images/cart/checkbox-check.svg") no-repeat
          right 50% top 50%;
        border: 1px solid #ffb81c;
      }
    }
  }
}

.a-radio-button {
  display: flex;
  height: 56px;
  width: 100%;
  input[type="radio"] {
    display: none;
  }
  turbo-frame {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  label {
    align-items: center;
    background: #f5f0eb;
    border: 1px solid #e2ddd9;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 8px 10px;
    position: relative;
    width: 100%;
    .circle {
      border: 1px solid #afc2df;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      height: 20px;
      position: relative;
      width: 20px;
    }
  }
  input[type="radio"]:checked + label {
    background: #ffe7b3;
    border: 2px solid #ffb81c;
    box-sizing: border-box;
    padding-left: 9px;
    padding-right: 9px;
    z-index: 2;
    .circle {
      background: #ffb81c;
      border: 1px solid #ffb81c;
      box-sizing: border-box;
      &:after {
        background: #4a4847;
        border-radius: 4px;
        content: "";
        height: 8px;
        left: 5px;
        position: absolute;
        top: 5px;
        width: 8px;
      }
    }
  }
}

.a-radio-button-groups {
  display: flex;
  flex-direction: column;
  width: 100%;
  .a-radio-button {
    &:first-child {
      label {
        border-radius: 8px 8px 0px 0px;
      }
    }
    &:not(:first-child) {
      margin-top: -1px;
      label {
        border-radius: 0px 0px 8px 8px;
      }
    }
    &--only-one {
      label {
        border-radius: 8px !important;
      }
    }
  }
}

.a-textarea {
  display: flex;
  flex-direction: row;
  position: relative;
  &__wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  textarea {
    @mixin body-regular-ui;
    align-items: center;
    background: #f5f0eb;
    border: 1px solid #e2ddd9;
    border-radius: 8px;
    box-sizing: border-box;
    color: #4a4847;
    display: flex;
    outline: none;
    padding: 28px 56px 11px 10px;
    width: 100%;
    &:focus {
      border: 1px solid #e2ddd9;
    }
  }
  label {
    align-items: center;
    background: #f5f0eb;
    @mixin caption-regular;
    color: #7a7775;
    display: flex;
    left: 10px;
    padding-top: 7px;
    position: absolute;
    top: 1px;
    width: calc(100% - 66px);
    .warning-icon {
      margin-right: 4px;
    }
  }
  &--error {
    input {
      border: 2px solid #ff7500;
      &:focus {
        border: 2px solid #ff7500;
      }
    }
    label {
      color: #ff7500;
    }
  }
  &--with-hide-button {
    textarea {
      flex-grow: 1;
      margin-right: 8px;
      width: auto;
    }
    .a-button-small {
      margin-right: -8px;
    }
  }
  &--hide {
    display: none;
  }
}

.a-link {
  color: #4a4847;
  cursor: pointer;
  outline: none;
  position: relative;
  text-decoration: none;
  &:hover {
    color: #5085cf;
    span {
      border-bottom: 1px solid #5085cf;
    }
  }
  &:active {
    color: #0957c3;
    span {
      border-bottom: 1px solid #0957c3;
    }
  }
  &:focus {
    color: #ff7500;
    span {
      border-bottom: 1px solid #ff7500;
    }
  }
  &:visited {
    color: #4a4847;
    &:hover {
      color: #5085cf;
      span {
        border-bottom: 1px solid #5085cf;
      }
    }
    &:active {
      color: #0957c3;
      span {
        border-bottom: 1px solid #0957c3;
      }
    }
    &:focus {
      color: #ff7500;
      span {
        border-bottom: 1px solid #ff7500;
      }
    }
  }
  &--small {
    @mixin body-regular-ui;
  }
  &--secondary {
    @mixin title-h2;
  }
}

.a-button-link {
  @mixin body-regular;
  color: #0957c3;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  &:hover {
    color: #7fade3;
  }
  &:active,
  &:focus,
  &:visited {
    color: #5085cf;
  }
  &:disabled {
    color: #c4c0bc;
  }
}
