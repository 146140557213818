.product-item {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  order: 4;
  &__block {
    margin-top: 24px;
    padding: 0 16px;
  }
  .a-product-card {
    order: unset;
  }
}

@mixin tablet {
  .product-item {
    order: unset;
    margin-top: 48px;
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 12px));
    grid-column-gap: 24px;
    &--second {
      .a-product-card {
        padding-left: 12px;
      }
      .title-h1-light {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        margin-top: auto;
      }
      .products-list__features {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .a-product-card {
        grid-column: 2 / span 1;
        grid-row: 1 / span 5;
      }
      .product-item__block {
        padding: 0px;
        margin-top: 32px;
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
      }
    }
    &--first {
      .a-product-card {
        padding-right: 12px;
      }
      .title-h1-light {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        margin-top: auto;
      }
      .products-list__features {
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
      }
      .a-product-card {
        grid-column: 1 / span 1;
        grid-row: 1 / span 4;
      }
      .product-item__block {
        padding: 0px;
        margin-top: 48px;
        grid-row: 4 / span 1;
        grid-column: 2 / span 1;
      }
    }
  }
}

@mixin desktop {
  .product-item {
    margin-top: 96px;
    &--first,
    &--second {
      .a-product-card {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    &--second,
    &--first {
      .product-item__block {
        margin-top: 48px;
      }
    }
    &--first {
      .a-product-card {
        grid-row: 1 / span 6;
      }
    }
  }
}
