@define-mixin title-h1-light {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
  @media (max-width: 1024px) {
    font-family: var(--font-main);
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: inherit;
  }
}
@define-mixin title-h1-big-light {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  font-weight: 300;
  line-height: 88px;
  @media (max-width: 1023px) {
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
  }
}
@define-mixin title-h1 {
  font-family: var(--font-main);
  font-style: normal;
  font-size: 48px;
  line-height: 56px;
  font-weight: 300;
  color: inherit;
  @mixin mobile {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
  }
}

@define-mixin title-h2 {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: inherit;
  @media (max-width: 1023px) {
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 32px;
    color: inherit;
  }
}

@define-mixin title-h2-light {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: inherit;
  @media (max-width: 1023px) {
    font-size: 22px;
    line-height: 32px;
    color: inherit;
  }
}

@define-mixin title-h3-semibold-ui {
  color: inherit;
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}

@define-mixin title-h3-semibold {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: inherit;
  @media (max-width: 1023px) {
    @mixin title-h3-light;
  }
}

@define-mixin title-h3-light {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: inherit;
}

@define-mixin title-h3-light-ui {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 24px;
  color: inherit;
}

@define-mixin body-semibold-ui {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
}

@define-mixin body-semibold {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
}

@define-mixin body-regular-ui {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
}

@define-mixin body-regular {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
}

@define-mixin caption-regular {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: inherit;
}

@define-mixin body-caps {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  @mixin mobile {
    font-size: 13px;
    line-height: 20px;
  }
}

@define-mixin caption-caps {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  color: inherit;
}

@define-mixin caption-semibold {
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: inherit;
}

@define-mixin link-primary {
  @mixin body-regular-ui;
  text-decoration: none;
  color: #0957c3;
  outline: none;
  cursor: pointer;
  &:hover,
  &:active {
    color: #6794d3;
  }
}

.body-regular-ui {
  @mixin body-regular-ui;
}

.body-regular {
  @mixin body-regular;
}

.caption-regular {
  @mixin caption-regular;
}

.caption-caps {
  @mixin caption-caps;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.title-h2 {
  @mixin title-h2;
}

.title-h3-semibold-ui {
  @mixin title-h3-semibold-ui;
}

.title-h3-semibold {
  @mixin title-h3-semibold;
}

.title-h1 {
  @mixin title-h1;
}

.title-h1-big-light {
  @mixin title-h1-big-light;
}

.title-h1-light {
  @mixin title-h1-light;
}

.title-h3-light {
  @mixin body-regular;
  @mixin desktop {
    @mixin title-h3-light;
  }
}

.caption-semibold {
  @mixin caption-semibold;
}
