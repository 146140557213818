.background-block {
  height: 1239px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
  &__top {
    background: linear-gradient(203.51deg, rgba(243, 116, 33, 0.86) 28.22%, rgba(244, 125, 32, 0.79) 42.53%, rgba(248, 151, 30, 0.53) 61.09%, rgba(252, 183, 27, 0.35) 77.18%, rgba(252, 183, 27, 0.27) 90.66%);
    bottom: 92.41%;
    filter: blur(100px);
    left: 40.1%;
    position: absolute;
    right: -50.24%;
    top: -29.22%;
    transform: matrix(0.96, 0.28, -0.28, 0.96, 0, 0);
  }
}
.background-block-v2 {
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
  &__top {
    background: linear-gradient(203.51deg, rgba(243, 116, 33, 0.86) 28.22%, rgba(244, 125, 32, 0.79) 42.53%, rgba(248, 151, 30, 0.53) 61.09%, rgba(252, 183, 27, 0.35) 77.18%, rgba(252, 183, 27, 0.27) 90.66%);
    filter: blur(100px);
    height: 437px;
    position: absolute;
    right: -213px;
    top: -270px;
    transform: matrix(0.96, 0.28, -0.28, 0.96, 0, 0);
    width: 437px;
  }
  &__bottom {
    background: linear-gradient(152.58deg, #F5F0EB -32.82%, #F5EDE1 -21.32%, #F6E6C8 -2.13%, #F8DB9E 22.39%, #FACB64 51.3%, #FCB71B 83.45%, #FCB71B 84.11%);
    filter: blur(100px);

    height: 559px;
    left: -286px;
    position: absolute;
    top: 444px;
    transform: matrix(0.1, 0.99, -0.99, 0.1, 0, 0);
    width: 559px;
  }
}

@mixin desktop {
  .background-block-v2 {
    &__top {
      height: 964px;
      width: 964px;
      right: -486px;
      top: -620px;
    }
    &__bottom {
      width: 878px;
      height: 878px;
      top: 420px;
      left: -475px;
    }
  }
}