#catalog-service {
  order: 2;
}
.catalog-service {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  margin-top: 40px;
  padding: 0px 16px;
  &__dot {
    display: none;
  }
  &__shipping {
    align-items: baseline;
    display: flex;
    flex-direction: row;
  }
  .title-h3-light {
    color: #4a4847;
    margin-top: 8px;
    &--trial {
      margin-top: 16px;
    }
  }
  .detail-text {
    @mixin body-regular-ui;
    color: #4a4847;
    font-style: italic;
  }
}
@mixin tablet {
  .catalog-service {
    padding: 0px;
    align-items: center;
    flex-direction: row;
    margin-bottom: 49px;
    margin-top: 16px;
    .title-h3-light {
      margin-left: 8px;
      margin-top: 0px;
      &--trial {
        margin-top: 0px;
      }
    }
    &__dot {
      display: flex;
      @mixin title-h3-light;
      color: rgba(74, 72, 71, 0.4);
      margin-left: 8px;
    }
  }
}

@mixin tablet {
  #catalog-service {
    grid-row: service / span 1;
    grid-column: 1 / span 12;
  }
}

@mixin desktop {
  .catalog-service {
    margin-bottom: 65px;
  }
}
