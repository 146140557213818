.editor-list {
  margin-bottom: 0px;
  @mixin body-regular;
  margin-top: 8px;
  padding-left: 48px;
  padding-right: 16px;
  li {
    &:not(:first-child) {
      padding-top: 8px;
    }
    &::marker {
      @mixin body-regular;
    }
  }
  &--unordered {
    li {
      &::marker {
        color: #ACA8A5;
      }
    }
  }
  &--ordered {
    li {
      &::marker {
        color: #FF7500;
      }
    }
  }
}

@mixin tablet {
  .editor-list {
    padding-left: 48px;
    padding-right: 0px;
    align-self: center;
    width: 100%;
    max-width: 452px;
    align-self: center;
  }
}

@mixin desktop {
  .editor-list {
    max-width: 660px;
  }
}