.double-image {
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 32px;
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    pitcure {
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    &--first {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    &--second {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
  }
  &__caption {
    @mixin caption-semibold;
    align-items: center;
    color: #7A7775;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 0 16px;
    &--first {
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
    }
  }
  &--stretched {
    grid-template-columns: repeat(1, 1fr);
    .double-image__wrapper {
      padding: 0px;
      width: 100%;
      &--first {

      }
      &--second {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        margin-top: 16px;
      }
    }
    .double-image__caption {
      &--first {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
      &--second {
        grid-column: 1 / span 1;
        grid-row: 4 / span 1;
      }
    }
  }
}

@mixin tablet {
  .double-image {
    grid-column: 4 / span 6;
    grid-column-gap: 24px;
    &__wrapper {
      &--first {
        picture {
          border-bottom-left-radius: 24px;
          border-top-left-radius: 24px;
        }
      }
      &--second {
        picture {
          border-bottom-right-radius: 24px;
          border-top-right-radius: 24px;
        }
      }
    }
    &__caption {
      padding: 0px;
      margin-top: 16px;
    }
    &--stretched {
      grid-column: 1 / span 12;
      grid-template-columns: repeat(2, 1fr);
      .double-image__wrapper {
        &--first {
          picture {
            border-radius: 24px;
          }
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }
        &--second {
          picture {
            border-radius: 24px;
          }
          grid-column: 2 / span 1;
          grid-row: 1 / span 1;
        }
      }
      .double-image__caption {
        &--first {
          grid-column: 1 / span 1;
          grid-row: 2 / span 1;
        }
        &--second {
          grid-column: 2 / span 1;
          grid-row: 2 / span 1;
        }
      }
    }
  }
}