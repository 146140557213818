.catalog-menu {
  background: #f5f0eb;
  display: flex;
  &__box {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 12;
    position: sticky;
    top: 40px;
    width: 100%;
    z-index: 3;
  }
  &__wrapper {
    border-bottom: 1px solid rgba(74, 72, 71, 0.2);
    border-top: 1px solid rgba(74, 72, 71, 0.2);
    display: flex;
    min-width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    @mixin body-regular-ui;
    color: #4a4847;
    cursor: pointer;
    flex-shrink: 0;
    margin: 5px 6px 6px;
    outline: none;
    padding-bottom: 1px;
    text-decoration: none;
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
    &:hover {
      background-image: linear-gradient(180deg, #5085cf 0, #5085cf);
      background-position: 0px 20px;
      background-repeat: repeat-x;
      background-size: 1px 1px;
      color: #5085cf;
    }
    &:active {
       background-image: none;
       background-position: 0px 20px;
       background-repeat: repeat-x;
       background-size: 1px 1px;
    }
    &:focus {
       background-image: none;
       background-position: 0px 20px;
       background-repeat: repeat-x;
       background-size: 1px 1px;
      color: #4a4847;
    }
    &--active {
      color: #ff7500;
      &:focus,
      &:active {
         background-image: none;
         background-position: 0px 20px;
         background-repeat: repeat-x;
         background-size: 1px 1px;
         color: #ff7500;
      }
      &:hover {
         background-image: none;
         background-position: 0px 20px;
         background-repeat: repeat-x;
         background-size: 1px 1px;
         color: #ff7500;
       }
    }
  }
  &__nav {
    background: #f5f0eb;
    border: none;
    cursor: pointer;
    height: 32px;
    opacity: 1;
    position: absolute;
    transition: opacity 100ms ease-out;
    width: 24px;
    &--hide {
      opacity: 0;
      pointer-events: none;
    }
    &--prev {
      left: 0px;
      padding: 0px;
      top: 1px;
      &:before {
        border-left: 1px solid rgba(74, 72, 71, 0.2);
        content: "";
        height: 20px;
        position: absolute;
        right: 0px;
        top: 6px;
      }
      &:after {
        background: url("../images/catalog/arrow-right.svg") no-repeat;
        content: "";
        height: 20px;
        left: 2px;
        position: absolute;
        top: 6px;
        transform: rotate(180deg);
        width: 20px;
      }
    }
    &--next {
      right: 0px;
      top: 1px;
      &:before {
        border-left: 1px solid rgba(74, 72, 71, 0.2);
        content: "";
        height: 20px;
        left: 0px;
        position: absolute;
        top: 6px;
      }

      &:after {
        background: url("../images/catalog/arrow-right.svg") no-repeat;
        content: "";
        height: 20px;
        position: absolute;
        right: 2px;
        top: 6px;
        width: 20px;
      }
    }
  }
}

@mixin tablet {
  .catalog-menu {
    transition: all 0.2s ease;
    width: 1344px;
    position: absolute;
    &__box {
      min-height: 46px;
      position: static;
      top: auto;
      width: 100%;
    }
    &__wrapper {
      padding-top: 6px;
      width: 100%;
      padding-bottom: 6px;
    }
    &__item {
      transition: margin-left 0.2s ease;
      &:first-child {
        margin-left: 0px;
      }
    }
    &--sticky {
      left: 0px !important;
      max-width: none;
      position: fixed;
      top: 48px;
      transition: all 0.2s ease;
      width: 100vw !important;
      z-index: 3;
      .catalog-menu__item {
        transition: margin-left 0.2s ease;
        &:first-child {
          margin-left: 48px;
        }
      }
    }
  }
}
