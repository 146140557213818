@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/averta-regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/averta-regularitalic.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/averta-semibold.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/averta-bold.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/averta-light.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/averta-thin.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/averta-extrathin.otf") format("opentype");
}
