:root {
  --color-beige: #f5f0eb;
  --color-blue: #0957c3;
  --color-gray: #565e5f;
  --color-gray-50: rgba(245, 240, 235, 0.5);
  --color-gray-90: rgba(86, 94, 95, 0.9);
  --color-gray-opacity-50: #a5a7a5;
  --color-light-blue: #7fade3;
  --color-light-peach: #fcc89b;
  --font-main: "Averta", "Arial", serif;
  --color-orange: #ff7500;
  --color-yellow: #ffb81c;
}
