@layer components {
  .compare-select-column {
    @apply z-20 flex row-start-1 md:row-start-2 sticky flex-col items-center px-2 -top-px;
    &--sticky {
      @apply border-b border-grey-main border-opacity-20 after:top-px after:border-b after:border-grey-main after:border-opacity-20;
      &:after {
        height: calc(100% + 1px) !important;
      }
    }
    &--1 {
      @apply bg-beige-main after:-right-4 md:after:-right-6 after:absolute after:h-full after:w-4 md:after:w-6 after:flex after:top-0 after:bg-beige-main;
    }
    &--2 {
      @apply bg-beige-main lg:after:-right-6 lg:after:absolute lg:after:h-full lg:after:w-6 lg:after:flex lg:after:top-0 lg:after:bg-beige-main;
    }
    &--3 {
      @apply bg-beige-main;
    }
  }
  .compare__rating {
    min-height: 42px;
  }
  .compare-bg {
    @apply bg-gradient-to-r from-yellow-main to-beige-main pr-4
           after:-right-4 md:after:-right-2 after:absolute after:h-full after:w-4 md:after:w-3 after:flex after:top-0 after:bg-beige-main;
    margin-left: -16px;
    &--first {
      @apply md:rounded-tr-full md:rounded-tl-full;
    }
  }
  .compare {
    .factoid {
      @apply text-h3-ui font-sans text-orange-main font-semibold md:font-normal md:text-h2;
    }
    br {
      content: "";
      display: block;
      @apply mt-1;
    }
  }
  .compare-select {
    @apply p-text pl-1 md:pl-2 text-blue-main outline-none py-3.5 z-20 relative pr-7 md:pr-8 bg-no-repeat bg-arrow-blue-main bg-transparent rounded-xl
           hover:text-blue-dark cursor-pointer hover:bg-arrow-blue-dark hover:bg-blue-main hover:bg-opacity-10;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-position: right 6px top 50%;
    box-sizing: border-box;
    max-width: 100%;
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
    text-overflow: "";
    white-space: normal;
    word-wrap: break-word;
    &::-ms-expand {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .compare-bg {
      margin-right: -16px;
    }
  }
}
