.checkout-total {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  #delivery-method-result {
    display: flex;
  }
  .checkout-text-link {
    @mixin link-primary;
  }
  &__lines {
    display: flex;
    flex-direction: column;
  }
  &__line {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .line-title,
    .line-value {
      @mixin body-regular-ui;
      color: #4a4847;
      margin-right: -1px;
      padding: 0px 1px;
      &--hide {
        display: none;
      }
    }
    &--discount {
      .line-value {
        color: #ff7500;
        height: 20px;
      }
    }
    &--vat,
    &--tax,
    &--unit {
      .line-value {
        height: 20px;
      }
    }
    &--total {
      border-bottom: 1px solid #efeae6;
      padding-bottom: 18px;
      .line-title,
      .line-value {
        @mixin title-h3-semibold-ui;
      }
      .line-value {
        height: 24px;
      }
    }
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  &__discount-form {
    .action-button-wrapper {
      margin-top: -28px;
      position: relative;
      width: 100%;
      z-index: 1;
      .a-button-combo-on-white {
        width: 100%;
      }
      .success-icon {
        position: absolute;
        right: 8px;
        top: -40px;
      }
    }
    .a-input {
      input {
        border-radius: 8px 8px 0px 0px;
        height: 84px;
        padding-top: 6px;
        background: #ffffff;

        &:disabled {
          background: #f5f0eb;
          @apply opacity-40;
        }
      }
    }
  }
  .a-button-primary {
    margin-top: 16px;
    width: 100%;
  }
  &__buttons {
    > * {
      height: 55px;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 300px;

      &:disabled svg {
        @apply opacity-40;
      }
    }
  }
}

.pseudo-input {
  border: 1px solid #e2ddd9;
  border-radius: 8px;
  color: #4a4847;
  padding: 20px 12px;
  height: 56px;
  font-size: 16px;
  line-height: 15px;
  @apply opacity-40;
}

.button-payment-request {
  color: #000000;
}

.button-klarna {
  color: #4a4847;
}

@mixin tablet {
  .checkout-total {
    &__discount-form {
      position: relative;
      margin-top: 16px;
      .a-input {
        input {
          border-radius: 8px 8px 0px 0px;
          height: 56px;
          padding: 28px 56px 11px 10px;
          border-radius: 8px 0px 0px 8px;
          width: calc(100% - 50px);
        }
        .success-icon {
          left: calc(100% - 100px);
        }
      }
      .action-button-wrapper {
        position: absolute;
        width: auto;
        bottom: 0px;
        right: 0px;
        .success-icon {
          left: -32px;
          top: 16px;
        }
      }
    }
  }
}
