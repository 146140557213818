@import "double-image.css";
@import "page-header.css";
@import "header-text.css";
@import "paragraph.css";
@import "list.css";

.editor {
  color: #4a4847;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 100%;
  a {
    color: #0957c3;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      color: #6794d3;
    }
  }
}

@mixin desktop {
  .editor {
    margin-bototm: 96px;
    .paragraph,
    .header-text,
    .editor-list {
      width: 50%;
    }
  }
}

.editor-table {
  br {
    content: "";
    display: block;
    margin-top: 8px;
  }
}
