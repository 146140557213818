@layer components {
  .t-input {
    @apply w-full border border-grey-super-light rounded-lg bg-beige-main text-p font-sans text-grey-main placeholder-grey-mid outline-none
    focus:border-yellow-main focus:border-2;
    padding: 27px 39px 7px 9px;

    &:focus {
      padding: 26px 38px 6px 8px;
    }

    &--bottom-wide {
      @apply rounded-t-lg rounded-b-none;
      padding: 27px 39px 35px 9px;

      &:focus {
        padding: 26px 38px 34px 8px;
      }
    }
  }

  .error-label {
    @apply absolute pl-5 font-sans text-caption text-red-main left-2.5 top-2 z-10
    before:content-[''] before:absolute before:top-px before:left-0 before:w-4 before:h-4  before:bg-warning;
  }
  .sad-checkbox {
    .sad-info {
      @apply absolute top-4 right-2.5 w-5 h-5 z-20;
    }
    input + div {
      @apply relative flex flex-row pl-2.5 rounded-lg border border-grey-super-light flex-shrink-0 p-text text-grey-main items-center cursor-pointer pr-10;
    }
    input:checked + div {
      @apply bg-orange-dirty relative border-yellow-main border-2;
      padding: 0px 39px 0px 9px;
      .sad-info {
        top: 15px;
        right: 9px;
      }
      .checked-box {
        @apply bg-yellow-main border-yellow-main;
        &:after {
          content: "";
          width: 14px;
          height: 11px;
          left: 3px;
          @apply absolute top-1 bg-checked;
        }
      }
    }
  }
  .checkbox {
    input:checked + div {
      @apply bg-yellow-main border-yellow-main;
      .checked-box {
        &:after {
          content: "";
          width: 14px;
          height: 11px;
          left: 3px;
          @apply absolute top-1 bg-checked;
        }
      }
    }
  }
}
